<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs v-if="achievementsOptions.id">
      <el-breadcrumb-item slot="after">
        {{ achievementsOptions.achievements == 1 ? '编辑绩效规则' : '编辑绩效' }}
      </el-breadcrumb-item>
    </Crumbs>
    <Crumbs v-else>
      <el-breadcrumb-item slot="after">{{
        options.id ? '编辑项目' : '新增项目'
      }}</el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain">
      <el-tabs @tab-click="handleClick" v-model="activeName" class="fullScreenMainCon">
        <template v-if="!options.id || options.isViewMonitoring"
          ><!-- 编辑进来的并且没有查看权限-->

          <el-tab-pane label="基本信息" v-if="permission(['INFORMATIONVIEW', 'INFORMATIONEDIT'])">
            <el-form
              class="column3"
              label-width="11em"
              label-position="top"
              :model="projectInformation"
              :rules="rules"
              ref="form"
            >
              <el-card>
                <div slot="header">
                  <span style="font-weight: 900; color: black">项目基本信息</span>
                  <el-button
                    class="projectEdit"
                    v-if="options.id"
                    type="success"
                    @click="projectIsShow = true"
                    >操作记录</el-button
                  >
                </div>
                <el-descriptions
                  class="margin-top"
                  title=""
                  style="width: 100%"
                  direction="vertical"
                  :column="7"
                  border
                >
                  <el-descriptions-item :span="2" label="项目名称">
                    <el-form-item
                      label-width="0px"
                      prop="projectName"
                      ref="projectName"
                      style="width: 100%; height: 52px"
                    >
                      <el-autocomplete
                        :fetch-suggestions="projectQuerySearch"
                        :disabled="disabled"
                        v-model="projectInformation.projectName"
                        @select="onProjectNameSelect"
                      >
                      </el-autocomplete>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item>
                    <template slot="label">
                      <span style="color: red">*</span>
                      项目经理
                    </template>

                    <el-form-item
                      label-width="0px"
                      style="width: 100%; height: 52px"
                      prop="managerName"
                      ref="managerName"
                    >
                      <el-input
                        v-if="disabled"
                        :disabled="disabled"
                        size="small"
                        v-model="projectInformation.managerName"
                      ></el-input>

                      <ChoiceData
                        v-else
                        :configure="{
                          request: '',
                          type: 'radio',
                          params: {
                            deptId: projectManagerDeptId,
                          },
                        }"
                        :projectManagerOptins="1"
                        :projectManagerDlgVisible="projectManagerDlgVisible"
                        :projectManagerFnList="projectManagerFnList"
                        @choiceDataChange="managerNameChange"
                      >
                        <el-input
                          @focus="buttonProjectManagerFn(1)"
                          slot="button"
                          ref="buttonProjectManager"
                          style="cursor: pointer"
                          size="small"
                          v-model="projectInformation.managerName"
                          placeholder="请选择项目经理"
                        ></el-input>
                        <div
                          class="mainList_operation clearfloat"
                          slot="search"
                          slot-scope="{ params, search }"
                        >
                          <div class="mainList_operation_search">
                            <el-select v-model="projectManager.id" placeholder="请选择部门">
                              <el-option
                                v-for="item in projectInformation.projectDeptList"
                                :label="item.deptName"
                                :value="item.deptId"
                                :key="item.deptId"
                              ></el-option>
                            </el-select>

                            <el-input
                              class="item"
                              placeholder="姓名"
                              clearable
                              v-model="projectManager.userName"
                            ></el-input>
                            <el-button
                              type="primary"
                              plain
                              icon="el-icon-search"
                              @click="projectManagerFn"
                              >搜索</el-button
                            >
                          </div>
                        </div>
                        <template slot="tableColumn-left">
                          <el-table-column
                            align="center"
                            prop="userName"
                            label="姓名"
                          ></el-table-column>
                          <el-table-column
                            align="center"
                            prop="phone"
                            label="手机号码"
                          ></el-table-column>
                          <el-table-column align="center" prop="fileName" label="岗位">
                            <template slot-scope="scope">
                              {{ scope.row.postId | dict(postData, 'id', 'postName') }}
                            </template>
                          </el-table-column>
                        </template>
                      </ChoiceData>
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <span style="color: red">*</span>
                      组长
                    </template>

                    <el-form-item
                      label-width="0px"
                      style="width: 100%; height: 52px"
                      prop="leaderManagerName"
                      ref="leaderManagerName"
                    >
                      <el-input
                        v-if="disabled"
                        :disabled="disabled"
                        size="small"
                        v-model="projectInformation.leaderManagerName"
                      ></el-input>

                      <ChoiceData
                        v-else
                        :configure="{
                          request: '',
                          type: 'radio',
                          params: {
                            deptId: projectManagerDeptId,
                          },
                        }"
                        :projectManagerOptins="1"
                        :projectManagerDlgVisible="projectManagerDlgVisible"
                        :projectManagerFnList="projectManagerFnList"
                        @choiceDataChange="leaderManagerNameChange"
                      >
                        <el-input
                          @focus="buttonProjectManagerFn(1)"
                          slot="button"
                          ref="buttonProjectLeaderManagerName"
                          style="cursor: pointer"
                          size="small"
                          v-model="projectInformation.leaderManagerName"
                          placeholder="请选择组长"
                        ></el-input>
                        <div
                          class="mainList_operation clearfloat"
                          slot="search"
                          slot-scope="{ params, search }"
                        >
                          <div class="mainList_operation_search">
                            <el-select v-model="projectManager.id" placeholder="请选择部门">
                              <el-option
                                v-for="item in projectInformation.projectDeptList"
                                :label="item.deptName"
                                :value="item.deptId"
                                :key="item.deptId"
                              ></el-option>
                            </el-select>

                            <el-input
                              class="item"
                              placeholder="姓名"
                              clearable
                              v-model="projectManager.userName"
                            ></el-input>
                            <el-button
                              type="primary"
                              plain
                              icon="el-icon-search"
                              @click="projectManagerFn"
                              >搜索</el-button
                            >
                          </div>
                        </div>
                        <template slot="tableColumn-left">
                          <el-table-column
                            align="center"
                            prop="userName"
                            label="姓名"
                          ></el-table-column>
                          <el-table-column
                            align="center"
                            prop="phone"
                            label="手机号码"
                          ></el-table-column>
                          <el-table-column align="center" prop="fileName" label="岗位">
                            <template slot-scope="scope">
                              {{ scope.row.postId | dict(postData, 'id', 'postName') }}
                            </template>
                          </el-table-column>
                        </template>
                      </ChoiceData>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="部门主管经理">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%; height: 52px"
                      prop="deptCompetentManagerId"
                      ref="deptCompetentManagerId"
                    >
                      <el-input
                        v-if="disabled"
                        :disabled="disabled"
                        v-model="projectInformation.competentManagerName"
                        placeholder="请选择部门主管经理"
                      ></el-input>

                      <ChoiceData
                        v-else
                        :configure="{
                          request: '',
                          type: 'radio',
                          params: {
                            deptId: deptCompetentManagerDeptId,
                          },
                        }"
                        :projectManagerOptins="1"
                        :projectManagerDlgVisible="projectManagerDlgVisible"
                        :deptCompetentManagerList="deptCompetentManagerList"
                        @choiceDataChange="deptCompetentManagerChange"
                      >
                        <el-input
                          slot="button"
                          @focus="buttonProjectManagerFn(2)"
                          ref="deptCompetentManager"
                          :readonly="true"
                          style="cursor: pointer"
                          size="small"
                          v-model="projectInformation.competentManagerName"
                          placeholder="请选择部门主管经理"
                        ></el-input>
                        <div
                          class="mainList_operation clearfloat"
                          slot="search"
                          slot-scope="{ params, search }"
                        >
                          <div class="mainList_operation_search">
                            <el-select v-model="deptCompetentManager.id" placeholder="请选择部门">
                              <el-option
                                v-for="item in projectInformation.projectDeptList"
                                :label="item.deptName"
                                :value="item.deptId"
                                :key="item.deptId"
                              ></el-option>
                            </el-select>

                            <el-input
                              class="item"
                              placeholder="姓名"
                              clearable
                              v-model="deptCompetentManager.userName"
                            ></el-input>
                            <el-button
                              type="primary"
                              plain
                              icon="el-icon-search"
                              @click="deptCompetentManagerFn"
                              >搜索</el-button
                            >
                          </div>
                        </div>
                        <template slot="tableColumn-left">
                          <el-table-column
                            align="center"
                            prop="userName"
                            label="姓名"
                          ></el-table-column>
                          <el-table-column
                            align="center"
                            prop="phone"
                            label="手机号码"
                          ></el-table-column>
                          <el-table-column align="center" prop="fileName" label="岗位">
                            <template slot-scope="scope">
                              {{ scope.row.postId | dict(postData, 'id', 'postName') }}
                            </template>
                          </el-table-column>
                        </template>
                      </ChoiceData>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="分管高管">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%; height: 52px"
                      prop="deptFzManagerId"
                      ref="deptFzManagerId"
                    >
                      <el-input
                        v-if="disabled"
                        :disabled="disabled"
                        v-model="projectInformation.fzManagerName"
                        placeholder="请选择分管高管"
                      ></el-input>
                      <ChoiceData
                        v-else
                        :configure="{
                          request: this.$api.project.listDepartmentHeadStaff,
                          type: 'radio',
                          params: {
                            deptId: deputyGeneralManagerDeptId,
                          },
                        }"
                        :projectManagerOptins="1"
                        :deputyGeneralManagerList="deputyGeneralManagerList"
                        @choiceDataChange="deputyGeneralManagerChange"
                      >
                        <el-input
                          slot="button"
                          :readonly="true"
                          style="cursor: pointer"
                          size="small"
                          @focus="buttonProjectManagerFn(3)"
                          v-model="projectInformation.fzManagerName"
                          placeholder="请选择分管高管"
                        ></el-input>
                        <div
                          class="mainList_operation clearfloat"
                          slot="search"
                          slot-scope="{ params, search }"
                        >
                          <div class="mainList_operation_search">
                            <el-select v-model="deputyGeneralId" placeholder="请选择部门">
                              <el-option
                                v-for="item in deptData"
                                :label="item.deptName"
                                :value="item.id"
                                :key="item.id"
                              ></el-option>
                            </el-select>

                            <el-input
                              class="item"
                              placeholder="姓名"
                              clearable
                              v-model="deputyGeneralName"
                            ></el-input>
                            <el-button
                              type="primary"
                              plain
                              icon="el-icon-search"
                              @click="deputyGeneralManagerFn"
                              >搜索</el-button
                            >
                          </div>
                        </div>
                        <template slot="tableColumn-left">
                          <el-table-column
                            align="center"
                            prop="userName"
                            label="姓名"
                          ></el-table-column>
                          <el-table-column
                            align="center"
                            prop="phone"
                            label="手机号码"
                          ></el-table-column>
                          <el-table-column align="center" prop="fileName" label="岗位">
                            <template slot-scope="scope">
                              {{ scope.row.postId | dict(postData, 'id', 'postName') }}
                            </template>
                          </el-table-column>
                        </template>
                      </ChoiceData>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="业主联系人">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="customersName"
                      ref="customersName"
                    >
                      <el-input
                        :disabled="disabled"
                        v-model="projectInformation.customersName"
                        placeholder="请输入业主联系人"
                      ></el-input>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="业主单位">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="customersUnit"
                      ref="customersUnit"
                    >
                      <el-input
                        :disabled="disabled"
                        v-model="projectInformation.customersUnit"
                        placeholder="请输入业主单位"
                      ></el-input>
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item label="项目承接公司">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="companyType"
                      ref="companyType"
                    >
                      <Dictionary
                        :disabled="disabled"
                        v-model="projectInformation.companyType"
                        code="COMPANY_TYPE"
                        placeholder="请选择项目承接公司"
                      />
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="实施地点" :span="2">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="implementSite"
                      ref="implementSite"
                    >
                      <el-input
                        :disabled="disabled"
                        v-model="projectInformation.implementSite"
                        placeholder="请输入实施地点"
                      ></el-input>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="预计开始日期">
                    <el-form-item
                      style="width: 100%"
                      label-width="0px"
                      prop="projectPredictStartDate"
                      ref="projectPredictStartDate"
                    >
                      <el-date-picker
                        :disabled="disabled"
                        value-format="timestamp"
                        v-model="projectInformation.projectPredictStartDate"
                        type="date"
                        :clearable="false"
                        placeholder="请选择预计开始日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item label="预计完成日期">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="projectPredictEndDate"
                      ref="projectPredictEndDate"
                    >
                      <el-date-picker
                        :disabled="disabled"
                        value-format="timestamp"
                        v-model="projectInformation.projectPredictEndDate"
                        type="date"
                        :clearable="false"
                        placeholder="请选择预计完成日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="实际开始日期">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="actualPredictStartDate"
                      ref="actualPredictStartDate"
                    >
                      <el-date-picker
                        :disabled="disabled"
                        value-format="timestamp"
                        v-model="projectInformation.actualPredictStartDate"
                        type="date"
                        :clearable="false"
                        placeholder="请选择实际开始日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="实际完成日期">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="accomplishDate"
                      ref="accomplishDate"
                    >
                      <el-date-picker
                        :disabled="disabled"
                        value-format="timestamp"
                        v-model="projectInformation.accomplishDate"
                        type="date"
                        :clearable="false"
                        placeholder="请选择实际完成日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="要求完成日期">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="requireAccomplishDate"
                      ref="requireAccomplishDate"
                    >
                      <el-date-picker
                        :disabled="disabled"
                        value-format="timestamp"
                        :clearable="false"
                        v-model="projectInformation.requireAccomplishDate"
                        type="date"
                        placeholder="请选择要求完成日期"
                      ></el-date-picker>
                    </el-form-item>
                  </el-descriptions-item>

                  <template v-if="options.id || achievementsOptions.id">
                    <el-descriptions-item label="合同名称">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="agreementName"
                        ref="agreementName"
                      >
                        <el-input
                          :disabled="true"
                          v-model="projectInformation.agreementName"
                          placeholder=""
                        ></el-input>
                      </el-form-item>
                    </el-descriptions-item>
                  </template>

                  <el-descriptions-item :span="1" label="剩余工作量比例(%)">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="residualWorkRatio"
                      ref="residualWorkRatio"
                    >
                      <el-input
                        :disabled="disabled"
                        v-model="projectInformation.residualWorkRatio"
                        @input="inputAmount('residualWorkRatio')"
                      ></el-input>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item
                    label="剩余工作量说明"
                    :span="projectInformation.id ? 3 : 4"
                  >
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="residualWorkRatioContent"
                      ref="residualWorkRatioContent"
                    >
                      <el-input
                        :disabled="disabled"
                        autosize
                        type="textarea"
                        v-model="projectInformation.residualWorkRatioContent"
                        placeholder="请输入剩余工作量说明"
                      ></el-input>
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item label="备注">
                    <el-form-item label-width="0px" style="width: 100%" prop="remark" ref="remark">
                      <el-input
                        :disabled="disabled"
                        autosize
                        type="textarea"
                        v-model="projectInformation.remark"
                        placeholder="请输入项目基本信息备注"
                      ></el-input>
                    </el-form-item>
                  </el-descriptions-item>
                </el-descriptions>
                <template v-if="projectInformation.status == 5">
                  <el-form-item
                    label="审批不通过意见"
                    label-width="0px"
                    prop="checkOpinion"
                    ref="checkOpinion"
                    style="color: red; width: 100%"
                    id="checkOpinion"
                  >
                    <el-input
                      style="font-size: 18px"
                      :disabled="true"
                      autosize
                      type="textarea"
                      v-model="projectInformation.checkOpinion"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-card>
              <div class="el-form-item" style="width: 100%; margin-top: 20px">
                <div class="el-form-item__content">
                  <el-button
                    type="primary"
                    :disabled="disabled"
                    @click="showChoicePost = true"
                    style="margin-bottom: 10px"
                    >选择部门</el-button
                  >

                  <table class="table">
                    <tr>
                      <th style="width: 80px">序号</th>
                      <th style="width: 200px">部门名称</th>
                      <th style="width: 200px">部门类型</th>
                      <th style="width: 180px">预算(元)</th>
                      <th style="width: 200px">任务占比(%)</th>
                      <th>任务分工说明</th>
                    </tr>
                    <template v-for="(item, index) in projectInformation.projectDeptList">
                      <tr :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item.deptName }}
                        </td>
                        <td>
                          <el-form-item
                            label-width="0"
                            :prop="`projectDeptList[${index}].type`"
                            :ref="`projectDeptList[${index}].type`"
                            :rules="[
                              {
                                required: true,
                                message: '请选择部门类型',
                                trigger: ['blur', 'change'],
                              },
                            ]"
                            style="width: 100%"
                          >
                            <Dictionary
                              :disabled="disabled"
                              size="small"
                              v-model="item.type"
                              code="Project_Dept"
                              placeholder="请选择"
                            />
                          </el-form-item>
                        </td>
                        <td>
                          <el-input
                            :disabled="disabled"
                            size="small"
                            v-model="item.predictAmount"
                            @input="inputAmountprojectDeptList(item.deptId)"
                            @focus="focusAmountprojectDeptList(item.deptId)"
                            @blur="blurAmountprojectDeptList(item.deptId)"
                          ></el-input>
                        </td>
                        <td>
                          <el-input-number
                            :disabled="disabled"
                            size="small"
                            v-model="item.taskRatio"
                            :min="0"
                          ></el-input-number>
                          <span v-if="proportionAll > 100 || proportionAll < 100" style="color: red"
                            >主办部门和协办部门的占比总和必须要满足100%</span
                          >
                        </td>
                        <td>
                          <el-input
                            type="textarea"
                            autosize
                            placeholder="请输入分工说明"
                            v-model="item.taskExplain"
                            :disabled="disabled"
                          >
                          </el-input>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>

              <el-card>
                <div slot="header">
                  <span style="font-weight: 900; color: black">业务类型</span>
                </div>
                <el-descriptions
                  class="margin-top"
                  direction="vertical"
                  :column="6"
                  border
                  style="width: 100%"
                >
                  <el-descriptions-item label="商务类型">
                    <el-form-item
                      style="width: 100%"
                      label-width="0px"
                      prop="businessType"
                      ref="businessType"
                    >
                      <Dictionary
                        :disabled="
                          disabled ||
                          options.projecNoIndex === 1 ||
                          options.projecNoIndex === 2 ||
                          false
                        "
                        v-model="projectInformation.businessType"
                        code="BUSINESS_TYPE"
                        placeholder="请选择"
                      />
                    </el-form-item>
                  </el-descriptions-item>
                  <template v-if="options.id || achievementsOptions.id">
                    <el-descriptions-item label="项目阶段">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="status"
                        ref="status"
                      >
                        <Dictionary
                          :disabled="true"
                          v-model="projectInformation.status"
                          code="PROJECT_STATUS"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                  </template>
                  <el-descriptions-item>
                    <template slot="label">
                      <span style="color: red">*</span>
                      项目来源
                      <el-button
                        size="mini"
                        type="warning"
                        style="margin-left: 10%; padding: 5px"
                        @click="projectSourceIsShow = true"
                        >项目来源说明</el-button
                      >
                    </template>
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="projectSource"
                      ref="projectSource"
                    >
                      <Dictionary
                        :disabled="
                          disabled ||
                          options.projecNoIndex === 1 ||
                          options.projecNoIndex === 2 ||
                          false
                        "
                        v-model="projectInformation.projectSource"
                        code="PROJECT_SOURCE"
                        placeholder="请选择项目来源"
                      />
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="合作费比例(%)"
                    v-if="projectInformation.projectSource == 'zi_zhi_he_zuo'"
                  >
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="collaborationCostsRatio"
                      ref="collaborationCostsRatio"
                    >
                      <el-input-number
                        :precision="2"
                        :step="0.1"
                        :max="100"
                        :disabled="disabled"
                        v-model="projectInformation.collaborationCostsRatio"
                      ></el-input-number>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item>
                    <template slot="label">
                      业务种类
                      <el-button
                        size="mini"
                        type="warning"
                        style="margin-left: 10%; padding: 5px"
                        @click="showBusinessLinesIsShow"
                        >业务种类说明</el-button
                      >
                    </template>
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="businessLines"
                      ref="businessLines"
                    >
                      <Dictionary
                        :disabled="disabled"
                        v-model="projectInformation.businessLines"
                        code="BUSINESS_LINES"
                        placeholder="请选择业务类型"
                      />
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="项目种类">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="projectLines"
                      ref="projectLines"
                    >
                      <Dictionary
                        :disabled="disabled"
                        v-model="projectInformation.projectLines"
                        :code="projectInformation.businessLines"
                        placeholder="请选择项目种类"
                      />
                    </el-form-item>
                  </el-descriptions-item>
                  <template v-if="options.id || achievementsOptions.id">
                    <el-descriptions-item label="项目绩效状态">
                      <el-form-item
                        label-width="0px"
                        style="width: 200px"
                        prop="performanceStatus"
                        ref="performanceStatus"
                      >
                        <Dictionary
                          :disabled="true"
                          v-model="projectInformation.performanceStatus"
                          code="PROJECT_PERFORMANCE_STATUS"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                  </template>
                </el-descriptions>
              </el-card>

              <el-card>
                <div slot="header">
                  <span style="font-weight: 900; color: black">项目金额</span>
                </div>
                <el-descriptions
                  class="margin-top"
                  direction="vertical"
                  :column="5"
                  style="width: 100%"
                  border
                >
                  <el-descriptions-item label="预计合同金额(元)">
                    <el-form-item
                      label-width="0px"
                      prop="predictContractAmount"
                      ref="predictContractAmount"
                      style="width: 100%"
                    >
                      <el-input
                        :disabled="disabled"
                        v-model="projectInformation.predictContractAmount"
                        @input="inputAmount('predictContractAmount')"
                        @focus="focusAmount('predictContractAmount')"
                        @blur="blurAmount('predictContractAmount')"
                      ></el-input>
                      <span style="color: red">{{ AmountInWords.predictContractAmount }}</span>
                    </el-form-item>
                  </el-descriptions-item>
                  <template v-if="options.id || achievementsOptions.id">
                    <el-descriptions-item label="实际合同金额(元)">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="actualContractAmount"
                        ref="actualContractAmount"
                      >
                        <el-input
                          :disabled="true"
                          @input="inputAmount('actualContractAmount')"
                          @focus="focusAmount('actualContractAmount')"
                          @blur="blurAmount('actualContractAmount')"
                          v-model="projectInformation.actualContractAmount"
                          placeholder="请输入"
                        ></el-input>
                        <span style="color: red">{{ AmountInWords.actualContractAmount }}</span>
                      </el-form-item>
                    </el-descriptions-item>
                  </template>

                  <!-- 项目板块暂时不放开外部商务费用字段 -->
                  <!-- <el-descriptions-item
                    label="预计外部商务费用(元)"
                    v-if="!business.id || business.isContentCooperation">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="predictExteriorBusinessCosts"
                      ref="predictExteriorBusinessCosts">
                      <el-input
                        :disabled="disabled"
                        @input="inputAmount('predictExteriorBusinessCosts')"
                        @focus="focusAmount('predictExteriorBusinessCosts')"
                        @blur="blurAmount('predictExteriorBusinessCosts')"
                        v-model="projectInformation.predictExteriorBusinessCosts"></el-input>
                      <span style="color: red">{{
                        AmountInWords.predictExteriorBusinessCosts
                      }}</span>
                    </el-form-item>
                  </el-descriptions-item> -->

                  <el-descriptions-item label="项目实际金额(元)">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="actualAmount"
                      ref="actualAmount"
                    >
                      <el-input
                        :disabled="disabled"
                        v-model="projectInformation.actualAmount"
                        @input="inputAmount('actualAmount')"
                        @focus="focusAmount('actualAmount')"
                        @blur="blurAmount('actualAmount')"
                        placeholder="请输入项目实际金额"
                      ></el-input>
                      <span style="color: red">{{ AmountInWords.actualAmount }}</span>
                    </el-form-item>
                  </el-descriptions-item>

                  <template v-if="options.id || achievementsOptions.id">
                    <el-descriptions-item label="招标费(元)">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="tenderFee"
                        ref="tenderFee"
                      >
                        <el-input
                          :disabled="disabled"
                          @input="inputAmount('tenderFee')"
                          @focus="focusAmount('tenderFee')"
                          @blur="blurAmount('tenderFee')"
                          v-model="projectInformation.tenderFee"
                        ></el-input>
                        <span style="color: red">{{ AmountInWords.tenderFee }}</span>
                      </el-form-item>
                    </el-descriptions-item>
                    <!-- 项目板块暂时不放开外部商务费用、可支配费用字段 -->
                    <!-- <el-descriptions-item label="外部商务费用(元)">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="exteriorBusinessCosts"
                        ref="exteriorBusinessCosts">
                        <el-input
                          :disabled="disabled"
                          @input="inputAmount('exteriorBusinessCosts')"
                          @focus="focusAmount('exteriorBusinessCosts')"
                          @blur="blurAmount('exteriorBusinessCosts')"
                          v-model="
                            projectInformation.exteriorBusinessCosts
                          "></el-input>
                        <span style="color: red">{{
                          AmountInWords.exteriorBusinessCosts
                        }}</span>
                      </el-form-item>
                    </el-descriptions-item> -->
                    <!-- <el-descriptions-item label="可支配费用(元)">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="actualDisposableProjectExpense"
                        ref="actualDisposableProjectExpense"
                      >
                        <el-input
                          :disabled="disabled"
                          @input="inputAmount('actualDisposableProjectExpense')"
                          @focus="focusAmount('actualDisposableProjectExpense')"
                          @blur="blurAmount('actualDisposableProjectExpense')"
                          v-model="projectInformation.actualDisposableProjectExpense"
                        ></el-input>
                        <span style="color: red">{{
                          AmountInWords.actualDisposableProjectExpense
                        }}</span>
                      </el-form-item>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="设备折旧费">
                      <el-form-item
                        label-width="0px"
                        style="width: 100%"
                        prop="depreciationEquipmentFee"
                        ref="depreciationEquipmentFee"
                      >
                        <el-input
                          :disabled="disabled"
                          @input="inputAmount('depreciationEquipmentFee')"
                          @focus="focusAmount('depreciationEquipmentFee')"
                          @blur="blurAmount('depreciationEquipmentFee')"
                          v-model="projectInformation.depreciationEquipmentFee"
                        ></el-input>
                        <span style="color: red">{{ AmountInWords.depreciationEquipmentFee }}</span>
                      </el-form-item>
                    </el-descriptions-item>
                  </template>
                </el-descriptions>
              </el-card>

              <el-card>
                <div slot="header">
                  <span style="font-weight: 900; color: black">税费</span>
                </div>
                <el-descriptions direction="vertical" style="width: 100%" :column="6" border>
                  <el-descriptions-item label="增值税类型" :span="3">
                    <template slot="label">
                      增值税类型
                      <el-button
                        size="mini"
                        type="warning"
                        style="margin-left: 10%; padding: 5px"
                        @click="showVatTypesShow"
                        >增值税类型说明</el-button
                      >
                    </template>
                    <el-form-item
                      label-width="0px"
                      prop="vatType"
                      ref="vatType"
                      style="width: 100%"
                    >
                      <el-select
                        v-model="projectInformation.vatType"
                        :disabled="disabled"
                        @change="dataUpdate"
                        placeholder="请选择增值税类型"
                        class="ipt_width"
                      >
                        <el-option
                          v-for="item in zzsList"
                          :key="item.id"
                          :label="item.vatName"
                          :value="item.vatType"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-descriptions-item>
                  <el-descriptions-item label="增值税类型详细">
                    <el-form-item prop="vatData" ref="vatData" style="width: 100%">
                      <VatDictionary
                        style="width: 100%"
                        valType="Data"
                        :disabled="disabled"
                        v-model="projectInformation.vatData"
                        :clearable="false"
                        :code="projectInformation.vatType"
                        placeholder="请选择增值税类型详细"
                      />
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="税费(元)">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%; height: 58px"
                      prop="taxesFee"
                      ref="taxesFee"
                    >
                      <span class="Taxes father">{{ SJ_taxes | thousands }}</span>
                      <span style="color: red" class="son">{{ AmountInWords.taxesFee }}</span>
                    </el-form-item>
                  </el-descriptions-item>
                </el-descriptions>

                <el-descriptions direction="vertical" :column="6" style="width: 100%" border>
                  <el-descriptions-item label="增值税税率(%)" labelClassName="taxLabelClassName">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="vatRate"
                      ref="vatRate"
                    >
                      <VatDictionary
                        style="width: 100%"
                        valType="TaxRate"
                        :disabled="disabled"
                        v-model="projectInformation.vatRate"
                        :code="projectInformation.vatData"
                        :clearable="false"
                        @optionsChange="optionsChange"
                        placeholder="请选择增值税税率"
                      />
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="附加税税率(%)" labelClassName="taxLabelClassName">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="additionalTaxRate"
                      ref="additionalTaxRate"
                    >
                      <span class="Taxes">12</span>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="印花税税率(%)" labelClassName="taxLabelClassName">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="stampTaxRate"
                      ref="stampTaxRate"
                    >
                      <span class="Taxes">0.03</span>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="增值税(元)" labelClassName="taxLabelClassName">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="actualVat"
                      ref="actualVat"
                    >
                      <span class="Taxes">{{ Sj_configcomp | thousands }}</span>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="附加税(元)" labelClassName="taxLabelClassName">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="actualAdditionalTax"
                      ref="actualAdditionalTax"
                    >
                      <span class="Taxes">{{ Sj_predictAdditionalTax | thousands }}</span>
                    </el-form-item>
                  </el-descriptions-item>

                  <el-descriptions-item label="印花税(元)" labelClassName="taxLabelClassName">
                    <el-form-item
                      label-width="0px"
                      style="width: 100%"
                      prop="actualStampTax"
                      ref="actualStampTax"
                    >
                      <span class="Taxes">{{ Sj_predictStampTax | thousands }}</span>
                    </el-form-item>
                  </el-descriptions-item>
                </el-descriptions>
              </el-card>
              <el-card v-if="(options.id && options.status >= 20) || achievementsOptions.id">
                <div slot="header">
                  <span style="font-weight: 900; color: black">预估成本方案</span>
                </div>
                <div style="width: 100%; flex: 1">
                  <CostPlan ref="costPlan" :options="options" :disabled="disabled" />
                </div>
              </el-card>

              <el-card v-if="options.id || achievementsOptions.id">
                <div slot="header">
                  <span style="font-weight: 900; color: black">会议信息</span>
                </div>
                <el-form-item
                  v-show="!(options.status == 0 || options.status == 10 || options.status == 20)"
                  label="项目立项会议纪要"
                  prop="projectApprovalMeetingSummary"
                  ref="projectApprovalMeetingSummary"
                  style="width: 100%"
                >
                  <el-input
                    :disabled="disabled || options.status != 40"
                    v-model="projectInformation.projectApprovalMeetingSummary"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-card>

              <el-card>
                <div slot="header">
                  <span style="font-weight: 900; color: black">外协信息</span>
                </div>
                <div class="el-form-item" style="width: 100%">
                  <div class="el-form-item__content" id="projectOutsourceList">
                    <el-form
                      :model="projectInformation"
                      :rules="rulesAgreement"
                      ref="formAgreement"
                    >
                      <el-table
                        :data="projectInformation.projectOutsourceList"
                        border
                        style="width: 100%"
                        :row-style="{ height: '80px' }"
                      >
                        <el-table-column align="center" type="index" label="序号" width="50">
                        </el-table-column>
                        <el-table-column prop="outsourceName" label="外协公司" width="180">
                          <template slot-scope="scope">
                            <el-input
                              :disabled="true"
                              size="small"
                              v-model="scope.row.outsourceName"
                              placeholder="请输入"
                            ></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="content" label="约定内容" width="220">
                          <template slot-scope="scope">
                            <el-input
                              :disabled="disabled"
                              size="small"
                              type="textarea"
                              autosize
                              v-model="scope.row.content"
                              placeholder="请输入"
                            ></el-input
                          ></template>
                        </el-table-column>
                        <el-table-column prop="memberQuantity" label="外协人数" width="120">
                          <template slot-scope="scope">
                            <el-input-number
                              :disabled="disabled"
                              size="small"
                              v-model="scope.row.memberQuantity"
                              :min="0"
                            ></el-input-number
                          ></template>
                        </el-table-column>
                        <el-table-column prop="outsourceCost" label="外协费用(元)" width="170">
                          <template slot-scope="scope">
                            <el-input
                              :disabled="disabled"
                              size="small"
                              v-model="scope.row.outsourceCost"
                              @focus="focusAmountprojectOutsourceList(scope.row.currentTimeId)"
                              @blur="blurAmountprojectOutsourceList(scope.row.currentTimeId)"
                              @input="inputAmountprojectOutsourceList(scope.row.currentTimeId)"
                            ></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="dockingPeople" label="对接人" width="110">
                          <template slot-scope="scope">
                            <el-input
                              :disabled="disabled"
                              size="small"
                              v-model="scope.row.dockingPeople"
                            ></el-input
                          ></template>
                        </el-table-column>
                        <el-table-column prop="phone" label="对接人电话" width="190">
                          <template slot-scope="scope">
                            <el-form-item
                              style="width: 100%; margin-bottom: 0px"
                              :prop="'projectOutsourceList.' + scope.$index + '.phone'"
                              :rules="rulesAgreement.phone"
                            >
                              <el-input
                                :disabled="disabled"
                                size="small"
                                v-model="scope.row.phone"
                              ></el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="projectOutsourceStartDate"
                          label="开始时间"
                          width="160"
                        >
                          <template slot-scope="scope">
                            <el-date-picker
                              :disabled="disabled"
                              value-format="timestamp"
                              type="date"
                              v-model="scope.row.projectOutsourceStartDate"
                              placeholder="请选择"
                            ></el-date-picker
                          ></template>
                        </el-table-column>

                        <el-table-column
                          prop="projectOutsourceAccomplishDate"
                          label="完成时间"
                          width="160"
                        >
                          <template slot-scope="scope">
                            <el-date-picker
                              :disabled="disabled"
                              value-format="timestamp"
                              type="date"
                              v-model="scope.row.projectOutsourceAccomplishDate"
                              placeholder="请选择"
                            ></el-date-picker
                          ></template>
                        </el-table-column>
                        <el-table-column prop="appraiseGrade" label="评价等级" width="110">
                          <template slot-scope="scope">
                            <el-select
                              :disabled="disabled"
                              v-model="scope.row.appraiseGrade"
                              placeholder="请选择评价等级"
                            >
                              <el-option
                                v-for="item in classOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.label"
                              >
                              </el-option>
                            </el-select>
                          </template>
                        </el-table-column>
                        <el-table-column prop="appraise" label="评价说明" width="220">
                          <template slot-scope="scope">
                            <el-input
                              :disabled="disabled"
                              size="small"
                              type="textarea"
                              autosize
                              v-model="scope.row.appraise"
                              placeholder="请输入评价说明"
                            ></el-input
                          ></template>
                        </el-table-column>
                        <el-table-column v-if="!disabled" fixed="right" width="60" align="center">
                          <template slot="header">
                            <div v-if="!disabled">
                              <i
                                @click="getProjectOutsourceList"
                                class="iconfont iconadd-circle"
                              ></i>
                            </div>
                          </template>
                          <template slot-scope="scope">
                            <div v-if="!disabled">
                              <i
                                class="iconfont iconerror"
                                @click="delProjectOutsourceList(scope.$index)"
                              ></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form>
                    <!-- 选择外协公司弹框 -->
                    <el-dialog
                      title="选择外协公司"
                      :visible.sync="isOutsource"
                      width="60%"
                      :modal="false"
                      @close="isOutsource = false"
                    >
                      <div>
                        <span style="margin-right: 10px"
                          >公司名称：
                          <el-input
                            placeholder="请输入公司名称"
                            clearable
                            v-model="selectPage.name"
                            class="ipt_width"
                            style="width: 70%; margin-bottom: 10px"
                          >
                          </el-input
                        ></span>
                        <el-button
                          type="primary"
                          icon="el-icon-search"
                          @click="handlePageNowChange(1)"
                          >搜索</el-button
                        >
                        <el-button
                          type="warning"
                          icon="el-icon-refresh"
                          @click="selectCompanyHandleReset"
                          >清空</el-button
                        >
                      </div>
                      <!-- 内容渲染单选表 -->
                      <el-table
                        :data="selectPartnerCompanyList"
                        border
                        stripe
                        style="width: 100%"
                        max-height="350"
                      >
                        <el-table-column fixed="left" label="操作" align="center" width="90">
                          <template slot-scope="scope">
                            <el-button
                              @click.native.prevent="choiceRow(scope.row)"
                              type="text"
                              size="small"
                            >
                              选择
                            </el-button>
                          </template>
                        </el-table-column>
                        <el-table-column prop="name" align="center" label="公司名称">
                        </el-table-column>
                        <el-table-column prop="phone" align="center" label="电话"></el-table-column>
                        <el-table-column prop="fax" align="center" label="传真"></el-table-column>
                        <el-table-column prop="mail" align="center" label="邮箱"></el-table-column>
                        <el-table-column
                          prop="address"
                          align="center"
                          label="地址"
                        ></el-table-column>
                        <el-table-column
                          prop="depositBank"
                          align="center"
                          label="开户银行"
                        ></el-table-column>
                        <el-table-column
                          prop="accountBank"
                          align="center"
                          label="银行账户"
                        ></el-table-column>
                      </el-table>
                      <el-pagination
                        @size-change="handlePagSizeChange"
                        @current-change="handlePageNowChange"
                        :current-page="selectPage.pageNow"
                        :page-sizes="[10, 20]"
                        :page-size="selectPage.pageSize"
                        layout="-> ,total, sizes, prev, pager, next, jumper"
                        :total="selectPage.total"
                      >
                      </el-pagination>
                      <span slot="footer" class="dialog-footer" style="padding-right: 45%">
                        <el-button @click="isOutsource = false" style="width: 125px"
                          >取 消</el-button
                        >
                      </span>
                    </el-dialog>
                  </div>
                </div>
              </el-card>
            </el-form>
          </el-tab-pane>

          <template
            v-if="
              options.projecNoIndex === undefined &&
              achievementsOptions.achievements != 1 &&
              achievementsOptions.achievements != 2
            "
          >
            <el-tab-pane
              label="商务信息"
              name="business"
              v-if="projectInformation.businessManageId"
            >
              <Manage
                ref="Manage"
                :isProject="true"
                :BusinessId="projectInformation.businessManageId"
              />
            </el-tab-pane>
            <el-tab-pane
              label="附件"
              name="annex"
              v-if="permission(['PROJECTFILEVIEW', 'PROJECTFILEEDIT'])"
            >
              <Annex ref="annex" :key="indexKey" :options="options" />
            </el-tab-pane>
            <!-- 是否有查看可支配费用权限  -->
            <template
              v-if="
                options.operatingButton &&
                options.operatingButton.some(r => {
                  return r == 'DISPOSABLE_EXPENSES'
                })
              "
            >
              <el-tab-pane
                label="项目可支配费用"
                name="cost"
                v-if="permission(['EXPENSESVIEW', 'EXPENSESEDIT']) && options.id"
                ><Cost ref="cost" :options="options" :projectSource="projectSource"
              /></el-tab-pane>
            </template>
          </template>
        </template>
        <template v-if="options.id && options.status != 0 && options.status != 10">
          <template v-if="options.projecNoIndex != 2">
            <el-tab-pane
              label="项目成员"
              :name="options.isViewMonitoring ? 'name' : ''"
              v-if="permission(['DEPTMEMBERVIEW', 'DEPTMEMBEREDIT'])"
              ><Member ref="member" :options="options" :deptData="deptData" :postData="postData"
            /></el-tab-pane>
            <el-tab-pane
              label="项目阶段"
              name="stage"
              v-if="permission(['STAGEPLANVIEW', 'STAGEPLANEDIT'])"
              ><Stage ref="stage" :options="options" @updateList="init"
            /></el-tab-pane>
          </template>
        </template>

        <template v-if="isContractInformation && options.isContractInformation == 1">
          <el-tab-pane
            label="合同信息"
            name="contractinformation"
            v-if="permission(['PROJECTAGREEMENTVIEW'])"
            ><ContractInformation ref="contractinformation" :options="options"
          /></el-tab-pane>
        </template>
        <template v-if="options.AssignProjectManager != 'AssignProjectManager'">
          <template v-if="options.ProjectDisable == 5 || options.ProjectDisable == 4">
            <el-tab-pane
              label="项目收入"
              v-if="permission(['INCOMEVIEW', 'INCOMEEDIT']) && options.isViewMonitoring"
              name="pjincome"
              ><PjIncome
                :introduce="'project'"
                ref="pjincome"
                @updateData="init"
                :options="options"
            /></el-tab-pane>
          </template>
        </template>
        <!-- 规则 -->
        <template v-if="achievementsOptions.achievements == 1">
          <el-tab-pane
            v-if="permission(['PROJECT_PERFORMANCE_RULES_VIEW'])"
            label="可支配费用规则"
            name="可支配费用规则"
          >
            <ExpenseRules
              :source="1"
              :rulesType="rulesType"
              :sourceDetailedType="sourceDetailedType"
              :projectSource="projectSource"
              :ExpenseRulesModule="ExpenseRules"
              :ExpenseRulesList="ExpenseRulesList"
              :usedExpenseRulesList="usedExpenseRulesList"
              ref="expenserules"
            ></ExpenseRules>
          </el-tab-pane>
          <el-tab-pane
            v-if="permission(['PROJECT_PERFORMANCE_RULES_VIEW'])"
            label="商务绩效规则"
            name="商务绩效规则"
          >
            <BusinessPerformance
              :source="1"
              :rulesType="rulesType"
              :sourceDetailedType="sourceDetailedType"
              :projectSource="projectSource"
              :BusinessPerformanceModule="BusinessPerformance"
              :BusinessPerformanceList="BusinessPerformanceList"
              :usedBusinessPerformanceList="usedBusinessPerformanceList"
              ref="businessperformance"
            ></BusinessPerformance>
          </el-tab-pane>
          <el-tab-pane
            v-if="permission(['PROJECT_PERFORMANCE_RULES_VIEW'])"
            label="部门绩效规则"
            name="部门绩效规则"
          >
            <DepartmentPerformance
              :source="1"
              :rulesType="rulesType"
              :sourceDetailedType="sourceDetailedType"
              :projectSource="projectSource"
              :DepartmentPerformanceModule="DepartmentPerformance"
              :DepartmentPerformanceList="DepartmentPerformanceList"
              :usedDepartmentPerformanceList="usedDepartmentPerformanceList"
              ref="departmentperformance"
            ></DepartmentPerformance>
          </el-tab-pane>
          <el-tab-pane
            v-if="permission(['PROJECT_PERFORMANCE_RULES_VIEW'])"
            label="浮动工资"
            name="浮动工资"
          >
            <FloatingWage
              :source="1"
              :rulesType="rulesType"
              :sourceDetailedType="sourceDetailedType"
              :projectSource="projectSource"
              :FloatingWageModule="FloatingWage"
              :FloatingWageList="FloatingWageList"
              :usedFloatingWageList="usedFloatingWageList"
              ref="floatingwage"
            ></FloatingWage>
          </el-tab-pane>
        </template>
        <!-- 绩效 -->
        <template v-if="achievementsOptions.achievements == 2">
          <el-tab-pane
            v-if="permission(['PROJECT_PERFORMANCE_VIEW'])"
            label="项目绩效"
            name="项目绩效"
          >
            <ProjectPerformance
              ref="projectperformance"
              :resettingColor="resettingColor"
              :projectDisRuleOptions="projectDisRuleOptions"
              :BUSINESSPerformanceRatioList="businessPerformanceRatioList"
              :DEPTPerformanceList="deptPerformanceList"
              :USEDProjectDisRuleDisDataResponse="usedProjectDisRuleDisDataResponse"
            ></ProjectPerformance>
          </el-tab-pane>
          <el-tab-pane v-if="permission(['COSTVIEW'])" label="项目成本" name="项目成本">
            <ProjectIncomeDetailed
              ref="projectincomedetailed"
              :projectDisRuleOptions="projectDisRuleOptions"
              :achievementsOptions="achievementsOptions"
            ></ProjectIncomeDetailed>
          </el-tab-pane>
        </template>
      </el-tabs>

      <div class="fullScreenOperation">
        <template v-if="options.id">
          <!-- <template
            v-if="
              options.operatingButton.some((r) => {
                return r == 'EDIT';
              })
            "
          > -->
          <template v-if="options.status == 0 || options.status == 5">
            <el-button
              type="primary"
              :loading="submitLoading"
              @click="submit('undeterminedEditProject', true)"
              >暂存</el-button
            >
            <el-button
              type="success"
              :loading="submitLoading"
              @click="submit('undeterminedEditOrSubmitProject', true)"
              >保存并提交</el-button
            >
          </template>
          <!-- </template> -->

          <template
            v-if="
              options.operatingButton.some(r => {
                return r == 'APPROVE'
              })
            "
          >
            <template v-if="options.status == 10 && options.projecExamine">
              <el-button type="success" :loading="submitLoading" @click="approve(2)"
                >通过</el-button
              >
              <el-button type="danger" :loading="submitLoading" @click="approve(1)"
                >不通过</el-button
              >
            </template>
          </template>

          <template
            v-if="
              permission(['STARTIMPLEMENT']) &&
              options.operatingButton.some(r => {
                return r == 'EDIT'
              })
            "
          >
            <template v-if="options.status == 40">
              <el-button
                type="success"
                :loading="submitLoading"
                @click="submit('projectStartImplement', true)"
                >项目确定立项</el-button
              >
            </template>
          </template>

          <template
            v-if="
              (options.staffAttribute == 'MANAGER' || options.staffAttribute == 'DEPT_MANAGER') &&
              options.status == 20
            "
          >
            <el-button
              type="success"
              :loading="submitLoading"
              @click="submit('taskPlanSubmitProject')"
              >提交阶段任务</el-button
            >
          </template>
          <template v-if="options.status != 0 && options.status != 5 && options.status != 10">
            <template
              v-if="
                options.operatingButton.some(r => {
                  return r == 'EDIT'
                })
              "
            >
              <template v-if="options.projecNoIndex != 2">
                <el-button
                  type="success"
                  :loading="submitLoading"
                  @click="submit('editProject', true)"
                  >保存</el-button
                >
              </template>
            </template>
          </template>
          <template
            v-if="
              options.estimate == 'submit' &&
              options.postAttribute == 'DEPT_MANAGER' &&
              activeName == 'costPlan'
            "
          >
            <el-button type="primary" :loading="submitLoading" @click="submitFn">提交</el-button>
          </template>
          <template
            v-if="
              options.estimate == 'approve' &&
              options.postAttribute == 'JH_HEAD' &&
              activeName == 'costPlan'
            "
          >
            <el-button type="success" :loading="submitLoading" @click="toExamine()">通过</el-button>
            <el-button type="danger" :loading="submitLoading" @click="toExamine(true)"
              >不通过</el-button
            >
          </template>
        </template>

        <template
          v-else-if="achievementsOptions.achievements == 1 || achievementsOptions.achievements == 2"
          ><template v-if="achievementsOptions.achievements == 2">
            <template
              v-if="
                projectDisRuleOptions.projectInformation &&
                projectDisRuleOptions.projectInformation.performanceStatus == 10 &&
                permission(['SUBMIT_PERFORMANCE_PLAN'])
              "
            >
              <el-button
                v-show="activeName != '0' && activeName != '项目成本'"
                type="primary"
                :loading="submitLoading"
                @click="achievements('editOrSubmit')"
                >确认绩效方案</el-button
              >
            </template>
            <template
              v-if="
                projectDisRuleOptions.projectInformation &&
                projectDisRuleOptions.projectInformation.performanceStatus == 10 &&
                permission(['PROJECT_PERFORMANCE_EDIT'])
              "
            >
              <el-button
                v-show="activeName != '0' && activeName != '项目成本'"
                type="success"
                :loading="submitLoading"
                @click="achievements('projectPerformanceEdit')"
                >保存</el-button
              >
            </template>
          </template>
          <template v-else>
            <template v-if="permission(['PROJECT_PERFORMANCE_RULES_EDIT'])">
              <el-button
                v-show="activeName != '0'"
                type="success"
                :loading="submitLoading"
                @click="achievements('selectRuleEdit')"
                >保存</el-button
              >
            </template>
          </template>
        </template>

        <template v-else>
          <el-button type="primary" :loading="submitLoading" @click="submit('creationProject')"
            >暂存</el-button
          >
          <el-button
            type="success"
            :loading="submitLoading"
            @click="submit('creationOrSubmitProject')"
            >暂存并提交</el-button
          >
        </template>

        <el-button type="info" @click="returnFn">返回</el-button>
      </div>
    </div>

    <ChoicePost
      v-if="showChoicePost"
      :isShow.sync="showChoicePost"
      :defaultChecked="defaultChecked"
      @determine="choicePostDetermine"
    ></ChoicePost>

    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="60%"
      :before-close="handleClose"
      id="approveTxet"
    >
      <div slot="title" class="header-title" style="color: #47a6fb; font-size: 15px">
        <span v-if="approveTxet == 1"> {{ '审批不通过' }}</span>
        <span v-else> {{ '审批通过' }}</span>
      </div>
      <el-form :model="form" :rules="rulesFormData" ref="rulesApproveTxet">
        <el-form-item prop="checkOpinion" v-if="approveTxet == 1">
          <el-input
            label="请填写不通过意见"
            v-model="form.checkOpinion"
            type="textarea"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
        <el-form-item v-else prop="">
          <el-input
            label="请填写通过意见"
            v-model="form.checkOpinion"
            type="textarea"
            placeholder="通过"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          :type="approveTxet == 1 ? 'danger' : 'success'"
          style="width: 120px"
          @click="addApprove"
          >{{ approveTxet == 1 ? '审批不通过' : '审批通过' }}</el-button
        >
        <el-button
          type="warning"
          plain
          @click="handleClose"
          style="margin-right: 37%; margin-left: 12%; width: 120px"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <el-dialog width="50%" title="项目来源说明" append-to-body :visible.sync="projectSourceIsShow">
      <div class="imgDialog">
        <img :src="`${imgProjectSource}?${new Date().getTime()}`" alt="项目来源说明" width="100%" />
      </div>
    </el-dialog>

    <el-dialog
      width="50%"
      title="业务种类说明"
      append-to-body
      :visible.sync="businessLinesIsShow"
      class="businessLinesDialog"
    >
      <el-table
        :data="businessLinesTableData"
        style="width: 100%"
        border
        height="550px"
        :cell-class-name="clearColumnPadding"
      >
        <el-table-column
          prop="dictName"
          label="业务种类"
          align="center"
          width="250px"
          class-name="height-light"
        >
        </el-table-column>
        <el-table-column label="项目种类" :show-overflow-tooltip="false">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.dictDataTwoList"
              style="width: 100%"
              :show-header="false"
              size="mini"
            >
              <el-table-column prop="dictName"> </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      width="60%"
      title="增值税类型说明"
      append-to-body
      :visible.sync="vatTypesShow"
      class="vatDialog"
    >
      <el-table
        :data="VatTypesTableData"
        size="mini"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        height="550px"
        :tree-props="{ children: 'dictDataTwoList' }"
        :cell-class-name="vatTypeTableCellStyle"
        :span-method="arraySpanMethod"
      >
        <el-table-column prop="vatName" label="内容"> </el-table-column>
        <el-table-column label="税率" :show-overflow-tooltip="false" width="100px">
          <template slot-scope="scope">
            <el-table
              size="mini"
              v-if="scope.row.dictDataThreeList && scope.row.dictDataThreeList.length !== 0"
              :data="scope.row.dictDataThreeList"
              style="width: 100%; height: 100%"
              :show-header="false"
              cell-class-name="cellContent"
            >
              <el-table-column prop="vatRate" width="100px" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  {{ scope.row.vatRate + '%' }}
                </template>
              </el-table-column>
              <el-table-column prop="remark" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  <div class="remark-box">
                    {{ scope.row.remark }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 项目操作记录 -->
    <ProjectLog :isShow.sync="projectIsShow" :options="projectInformation" />
  </div>
</template>
<script>
import {
  getInputValue,
  delcommafy,
  numberToCurrencyNo,
  changeMoneyToChinese,
} from '@/util/jsencrypt'
import { initTreeData } from '@/util/common'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    ChoicePost: () => import('@/components/ChoicePost.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    Annex: () => import('./Annex.vue'),
    Cost: () => import('./Cost.vue'),
    Member: () => import('./Member.vue'),
    Stage: () => import('./Stage.vue'),
    CostPlan: () => import('./CostPlan.vue'),
    PjIncome: () => import('./PjIncome.vue'),
    ContractInformation: () => import('./ContractInformation.vue'),
    ProjectPerformance: () => import('./ProjectPerformance.vue'),
    ProjectIncomeDetailed: () => import('./ProjectIncomeDetailed.vue'),
    ExpenseRules: () => import('@/views/globalRules/ExpenseRules.vue'),
    BusinessPerformance: () => import('@/views/globalRules/BusinessPerformance.vue'),
    DepartmentPerformance: () => import('@/views/globalRules/DepartmentPerformance.vue'),
    FloatingWage: () => import('@/views/globalRules/FloatingWage.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    ProjectLog: () => import('@/components/project/project-log.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    achievementsOptions: {
      type: Object,
      default: function () {
        return {}
      },
    },
    business: {
      type: Object,
      default: function () {
        return {}
      },
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    StautsType: {
      type: Array,
      default: function () {
        return []
      },
    },
    ProjectType: {
      type: Array,
      default: function () {
        return []
      },
    },
    performanceStatus: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    // 项目名称自定义校验规则
    var validateProject = (rule, value, callback) => {
      if (!this.disabled) {
        if (value == '') {
          callback(new Error('请输入项目名称'))
        } else {
          let flag = this.projectNameList.some(item => item.value == value)
          if (flag && value !== this.selectProjectName) {
            // 说明有重复的项目名称，回显校验提示内容
            callback(new Error('当前项目名称与历史项目重复，请注意修改'))
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    }
    return {
      indexKey: 0,
      showChoicePost: false,
      showEditDialog: false,
      loading: true,
      disabled: false,
      submitLoading: false,
      isOutsource: false,
      activeName: '0',
      rules: {
        projectName: [{ validator: validateProject, trigger: 'change' }],
        managerName: [
          {
            required: true,
            message: '请选择项目经理',
            trigger: 'blur',
          },
        ],
        leaderManagerName: [
          {
            required: true,
            message: '请选择组长',
            trigger: 'blur',
          },
        ],
      },
      deptData: [],
      postData: [],
      defaultChecked: [],
      configure: {
        request: this.$api.user.listStaff,
        type: 'radio',
      },
      selectPartnerCompanyList: [], //合作方公司列表数据
      // 分页参数
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      newArr: [],
      selectPage: { pageNow: 1, pageSize: 10, total: 0, name: '' },
      dialogVisible: false,
      approveTxet: '',
      form: {
        id: '',
        checkOpinion: '',
      },
      rulesFormData: {
        checkOpinion: [
          {
            required: true,
            message: '请输入不通过的意见内容',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 500,
            message: '意见内容在0~500个字符',
            trigger: 'blur',
          },
        ],
      },
      classOptions: [
        {
          label: '优',
        },
        {
          label: '良',
        },
        {
          label: '中',
        },
        {
          label: '差',
        },
      ],
      isContractInformation: false,
      projectSource: [],
      Project_Dept: [],
      AmountInWords: {
        predictContractAmount: null,
        projectBudget: null,
        actualContractAmount: null,
        depreciationEquipmentFee: null,
        taxesFee: null,
        tenderFee: null,
        actualAmount: null,
        actualVat: null,
        actualAdditionalTax: null,
        actualStampTax: null,
      },

      zzsList: [],
      projectManagerDeptId: null,
      projectManagerFnList: [],
      projectManager: {
        id: '',
        userName: null,
      },
      projectManagerDlgVisible: false,

      deptCompetentManagerList: [],
      deptCompetentManagerDeptId: null,
      deptCompetentManager: {
        id: '',
        userName: null,
      },

      deputyGeneralManagerList: [],
      deputyGeneralManagerDeptId: null,
      deputyGeneralId: null,
      deputyGeneralName: null,
      user_Data: [],
      BusinessPerformance: {
        module: '',
        projectId: 0,
      },
      DepartmentPerformance: {
        module: '',
        projectId: 0,
      },
      ExpenseRules: {
        module: '',
        projectId: 0,
      },
      FloatingWage: {
        module: '',
        projectId: 0,
      },
      BusinessPerformanceList: [],
      DepartmentPerformanceList: [],
      ExpenseRulesList: [],
      FloatingWageList: [],
      usedBusinessPerformanceList: [],
      usedDepartmentPerformanceList: [],
      usedExpenseRulesList: [],
      usedFloatingWageList: [],
      oldData: '可支配费用规则',
      rulesType: [],
      projectDisRuleOptions: {},
      usedProjectDisRuleDisDataResponse: {},
      businessPerformanceRatioList: [],
      deptPerformanceList: [],
      projectPerformanceIsAlreadyChangeRequest: {
        basicBusinessFeeDis: 0,
        basicBusinessPerformanceDis: 0,
        businessOperationFeeDis: 0,
        companyBasicChargeDis: 0,
        productionActualCostDis: 0,
        productionPerformanceDis: 0,
        productionQuotaCostDis: 0,
        projectPerformanceDis: 0,
        businessPerformanceDis: 0,
        corporateProfitDis: 0,
        implementationManagementPerformanceDis: 0,
        projectExpenseSumDis: 0,
        costUpgradingDis: 0,
        deploymentMaintenanceCostDis: 0,
        productPerformanceDis: 0,
        costUpgradingPerformanceDis: 0,
        deploymentMaintenanceCostPerformanceDis: 0,
        productDevelopmentCostDis: 0,
        staffProductionCostDis: 0,
        outSourceSupervisionPerformanceDis: 0,
        outsourceCostDis: 0,
        sumBusinessPerformanceDis: 0,
        actualDisposableProjectExpenseDis: 0,
      },
      resettingColor: 0,
      sourceDetailedType: [],
      rulesAgreement: {
        phone: [
          {
            // required: true,
            validator: this.partyPhone,
            trigger: ['blur', 'change'],
          },
        ],
      },
      oldvatRate: {},
      projectSourceIsShow: false,
      businessLinesIsShow: false,
      projectNameList: [], //项目名称数组
      selectProjectName: '',
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/业务种类.png',
      imgProjectSource: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/生产费用.png',
      temporaryVatRate: '',
      vatTypesShow: false,
      imgVatTypes: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/税率表.png',

      businessLinesTableData: [], //业务种类弹框数据
      VatTypesTableData: [], //增值税弹框数据
      projectIsShow: false, //操作记录
      oldProjectCostPlanFileList: [],
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      originalForm: state => state.project.originalForm,
      projectCostPlanFileList: state => state.project.projectCostPlanFileList,
      projectCostPlanList: state => state.project.projectCostPlanList,
      projectDisposableExpenses: state => state.project.projectDisposableExpenses,
      projectDeptMemberList: state => state.project.projectDeptMemberList,
      memberData: state => state.project.memberData,
    }),
    proportionAll() {
      let num = 0
      if (this.newArr.length > 0) {
        for (let index = 0; index < this.newArr.length; index++) {
          const a = this.newArr[index]
          num += a.taskRatio
        }
      }
      return num
    },
    Sj_predictAdditionalTax() {
      //实际附加税
      let num = 0
      num = (this.Sj_configcomp * 0.12).toFixed(2)
      return Number(num)
    },
    Sj_predictStampTax() {
      //实际印花税
      let num = 0
      num = (delcommafy(this.projectInformation.actualAmount) * 0.0003).toFixed(2)
      return Number(num)
    },
    Sj_configcomp() {
      //实际增值税

      let num = 0
      num = (
        (((Number(delcommafy(this.projectInformation.actualAmount)) * 10000) /
          (1000000 + this.projectInformation.vatRate * 10000)) *
          (this.projectInformation.vatRate * 10000)) /
        10000
      ).toFixed(2)
      return Number(num)
    },
    SJ_taxes() {
      //实际总税费
      let num = 0
      let num1 = this.Sj_predictStampTax
      let num2 = this.Sj_predictAdditionalTax
      let num3 = this.Sj_configcomp
      num = Number(num1) + Number(num2) + Number(num3)
      num = Number(num.toFixed(2))
      this.AmountInWords.taxesFee = changeMoneyToChinese(num)
      return num
    },
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.activeName = '0'
        this.showEditDialog = newVal
      },
    },
    achievementsOptions: {
      deep: true, //对象深度监测
      immediate: true, //初始化立即执行
      handler: function (achievementsOptions) {
        if (achievementsOptions.id) {
          this.disabled = true
        }
        this.init()
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        this.disabled = false
        if (options.projecNoIndex === 1 || options.projecNoIndex === 2) {
          this.disabled = true
        } else if (options.id) {
          if (
            this.permission(['INFORMATIONEDIT']) === false ||
            options.isEditMonitoring === false
          ) {
            this.disabled = true
          } else if (this.permission(['INFORMATIONEDIT'])) {
            if (
              options.isEditMonitoring &&
              options.status == 0 &&
              options.operatingButton.some(r => {
                return r == 'EDIT'
              })
            ) {
              this.disabled = false
            }
          }
        } else {
          this.disabled = false
        }
        this.init()
        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.clearValidate()
          }
        })
      },
    },
    originalForm: {
      //immediate:true,//初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        var arr = []
        try {
          if (newVal.projectInformation) {
            newVal.projectInformation.projectDeptList.some(r => {
              arr.push(r.deptId)
            })
          }
        } catch (e) {
          console.log(e)
        }
        this.defaultChecked = arr

        if (newVal.agreementList) {
          if (newVal.agreementList.length > 0) {
            this.isContractInformation = true
          } else {
            this.isContractInformation = false
          }
        }
        this.$forceUpdate()
      },
    },
    projectInformation: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.newArr = newVal.projectDeptList
      },
    },
    'projectInformation.deptCompetentManagerId': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.deptCompetentManagerList.forEach(v => {
          if (v.id == newVal) {
            this.projectInformation.competentManagerName = v.userName
            this.deptCompetentManagerDeptId = v.deptList[0]
          }
        })
      },
    },
    'projectInformation.deptFzManagerId': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.deputyGeneralManagerList.forEach(v => {
          if (v.id == newVal) {
            this.projectInformation.fzManagerName = v.userName
            this.deputyGeneralManagerDeptId = v.deptList[0]
          }
        })
      },
    },
    'projectInformation.projectSource': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal) {
          this.$api.dict
            .listSysDictData(newVal, true)
            .then(res => {
              this.sourceDetailedType = res.data
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
  },
  filters: {
    thousands(a) {
      if (!a) {
        return a
      } else {
        a = Number(a).toFixed(2)
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  created() {
    this.getProjectNameList()
    this.getStaffList()
    Promise.all([this.$api.sysDept.listDept(), this.$api.sysConfig.listPost()])
      .then(r => {
        this.deptData = r[0].data
        this.postData = r[1].data
      })
      .catch(err => {})
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.projectSource = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('Project_Dept', true)
      .then(res => {
        this.Project_Dept = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.vat
      .getVatTypeList()
      .then(res => {
        this.zzsList = res?.data?.records
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.project.listDepartmentHeadStaff({ pageSize: 1000000 }).then(res => {
      if (res.data.records) {
        this.deputyGeneralManagerList = res.data.records
      }
    })
  },

  beforeDestroy() {
    this.indexKey = 0
  },
  methods: {
    selectCompanyHandleReset() {
      this.selectPage.name = ''
      this.handlePageNowChange(1)
    },
    optionsChange(v) {
      if (v[0]) {
        const vatRate = v.find(item => item.vatType == this.temporaryVatRate)
        if (vatRate) {
          this.projectInformation.vatRate = this.temporaryVatRate
        } else {
          this.projectInformation.vatRate = v[0].vatType
        }
      }
    },
    getProjectNameList() {
      this.$api.project
        .getNameListNoPage({})
        .then(res => {
          if (res) {
            this.projectNameList = res.data.map(item => {
              return { value: item }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    toExamine(n) {
      let obj = this.$refs.costPlan.fileData[0]
      if (n) {
        this.$refs.costPlan.toExamine('', obj, n)
      } else {
        this.$refs.costPlan.toExamine('', obj)
      }
    },
    async submitFn() {
      await this.$refs.costPlan.submit()
    },
    handleClick() {
      if (this.achievementsOptions.achievements == 1) {
        let ExpenseRulesisShow = false
        let BusinessPerformanceisShow = false
        let DepartmentPerformanceisShow = false
        let FloatingWageisShow = false
        if (this.oldData == '0') {
          this.oldData = this.activeName
          return
        }
        if (this.oldData == '可支配费用规则') {
          try {
            this.usedExpenseRulesList.forEach(v => {
              this.ExpenseRulesList.forEach(k => {
                v.ruleList.forEach(a1 => {
                  k.ruleList.forEach(a2 => {
                    if (a1.ruleDataList.length > 0 && a2.ruleDataList.length > 0) {
                      a1.ruleDataList.forEach(b1 => {
                        a2.ruleDataList.forEach(b2 => {
                          if (
                            b1.id == b2.id &&
                            b1.ruleType == 'PER_CENT' &&
                            b2.ruleType == 'PER_CENT'
                          ) {
                            if (Number(b1.code) != Number(b2.code)) {
                              ExpenseRulesisShow = true
                              throw new Error('EndIterative')
                            }
                          }
                          if (b1.ruleGroupList.length > 0 && b2.ruleGroupList.length > 0) {
                            b1.ruleGroupList.forEach(c1 => {
                              b2.ruleGroupList.forEach(c2 => {
                                if (
                                  c1.id == c2.id &&
                                  c1.ruleType == 'VALUE' &&
                                  c2.ruleType == 'VALUE'
                                ) {
                                  if (Number(c1.code) != Number(c2.code)) {
                                    ExpenseRulesisShow = true
                                    throw new Error('EndIterative')
                                  }
                                }
                              })
                            })
                          }
                        })
                      })
                    }
                  })
                })
              })
            })
          } catch (e) {}
          if (ExpenseRulesisShow) {
            this.$confirm('可支配费用规则已修改末保存, 是否继续切换?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                //  确定
              })
              .catch(() => {
                // 取消
                this.activeName = '可支配费用规则'
                this.oldData = this.activeName
              })
          }
          this.oldData = this.activeName
          return
        }
        if (this.oldData == '商务绩效规则') {
          try {
            this.usedBusinessPerformanceList.forEach(v => {
              this.BusinessPerformanceList.forEach(k => {
                v.ruleList.forEach(a1 => {
                  k.ruleList.forEach(a2 => {
                    if (a1.ruleDataList.length > 0 && a2.ruleDataList.length > 0) {
                      a1.ruleDataList.forEach(b1 => {
                        a2.ruleDataList.forEach(b2 => {
                          if (
                            b1.id == b2.id &&
                            b1.ruleType == 'PER_CENT' &&
                            b2.ruleType == 'PER_CENT'
                          ) {
                            if (Number(b1.code) != Number(b2.code)) {
                              BusinessPerformanceisShow = true
                              throw new Error('EndIterative')
                            }
                          }
                          if (b1.ruleGroupList.length > 0 && b2.ruleGroupList.length > 0) {
                            b1.ruleGroupList.forEach(c1 => {
                              b2.ruleGroupList.forEach(c2 => {
                                if (
                                  c1.id == c2.id &&
                                  c1.ruleType == 'VALUE' &&
                                  c2.ruleType == 'VALUE'
                                ) {
                                  if (Number(c1.code) != Number(c2.code)) {
                                    BusinessPerformanceisShow = true
                                    throw new Error('EndIterative')
                                  }
                                }
                              })
                            })
                          }
                        })
                      })
                    }
                  })
                })
              })
            })
          } catch (e) {}
          if (BusinessPerformanceisShow) {
            this.$confirm('商务绩效规则已修改末保存, 是否继续切换?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                //  确定
              })
              .catch(() => {
                // 取消
                this.activeName = '商务绩效规则'
                this.oldData = this.activeName
              })
          }
          this.oldData = this.activeName
          return
        }
        if (this.oldData == '部门绩效规则') {
          try {
            this.DepartmentPerformanceList.forEach(v => {
              this.usedDepartmentPerformanceList.forEach(k => {
                v.ruleList.forEach(a1 => {
                  k.ruleList.forEach(a2 => {
                    if (a1.ruleDataList.length > 0 && a2.ruleDataList.length > 0) {
                      a1.ruleDataList.forEach(b1 => {
                        a2.ruleDataList.forEach(b2 => {
                          if (
                            b1.id == b2.id &&
                            b1.ruleType == 'PER_CENT' &&
                            b2.ruleType == 'PER_CENT'
                          ) {
                            if (Number(b1.code) != Number(b2.code)) {
                              DepartmentPerformanceisShow = true
                              throw new Error('EndIterative')
                            }
                          }
                          if (b1.ruleGroupList.length > 0 && b2.ruleGroupList.length > 0) {
                            b1.ruleGroupList.forEach(c1 => {
                              b2.ruleGroupList.forEach(c2 => {
                                if (
                                  c1.id == c2.id &&
                                  c1.ruleType == 'VALUE' &&
                                  c2.ruleType == 'VALUE'
                                ) {
                                  if (Number(c1.code) != Number(c2.code)) {
                                    DepartmentPerformanceisShow = true
                                    throw new Error('EndIterative')
                                  }
                                }
                              })
                            })
                          }
                        })
                      })
                    }
                  })
                })
              })
            })
          } catch (e) {}
          if (DepartmentPerformanceisShow) {
            this.$confirm('部门绩效规则已修改末保存, 是否继续切换?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                //  确定
              })
              .catch(() => {
                // 取消
                this.activeName = '部门绩效规则'
                this.oldData = this.activeName
              })
          }
          this.oldData = this.activeName
          return
        }
        if (this.oldData == '浮动工资') {
          try {
            this.FloatingWageList.forEach(v => {
              this.usedFloatingWageList.forEach(k => {
                v.ruleList.forEach(a1 => {
                  k.ruleList.forEach(a2 => {
                    if (a1.ruleDataList.length > 0 && a2.ruleDataList.length > 0) {
                      a1.ruleDataList.forEach(b1 => {
                        a2.ruleDataList.forEach(b2 => {
                          if (
                            b1.id == b2.id &&
                            b1.ruleType == 'PER_CENT' &&
                            b2.ruleType == 'PER_CENT'
                          ) {
                            if (Number(b1.code) != Number(b2.code)) {
                              FloatingWageisShow = true
                              throw new Error('EndIterative')
                            }
                          }
                          if (b1.ruleGroupList.length > 0 && b2.ruleGroupList.length > 0) {
                            b1.ruleGroupList.forEach(c1 => {
                              b2.ruleGroupList.forEach(c2 => {
                                if (
                                  c1.id == c2.id &&
                                  c1.ruleType == 'VALUE' &&
                                  c2.ruleType == 'VALUE'
                                ) {
                                  if (Number(c1.code) != Number(c2.code)) {
                                    FloatingWageisShow = true
                                    throw new Error('EndIterative')
                                  }
                                }
                              })
                            })
                          }
                        })
                      })
                    }
                  })
                })
              })
            })
          } catch (e) {}
          if (FloatingWageisShow) {
            this.$confirm('浮动工资规则已修改末保存, 是否继续切换?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                //  确定
              })
              .catch(() => {
                // 取消
                this.activeName = '浮动工资'
                this.oldData = this.activeName
              })
          }
          this.oldData = this.activeName
          return
        }
      } else if (this.achievementsOptions.achievements == 2) {
        let projectDisRuleOptionsShow = false
        let businessDisShow = false
        let deptPerformanceDisShow = false

        if (this.oldData == '可支配费用规则') {
          this.oldData = '项目绩效'
        }
        if (this.oldData == '0' || this.oldData == '项目成本') {
          this.oldData = this.activeName
          return
        }
        if (this.oldData == '项目绩效') {
          try {
            let projectPerformanceIsAlreadyChangeRequest =
              this.$refs.projectperformance.projectPerformanceIsAlreadyChangeRequest
            let arr = []
            for (const key in projectPerformanceIsAlreadyChangeRequest) {
              arr.push(projectPerformanceIsAlreadyChangeRequest[key])
            }
            if (arr.indexOf(1) != -1) {
              projectDisRuleOptionsShow = true
            }
            if (
              this.projectDisRuleOptions.projectPerformanceDisDataResponse
                .businessPerformanceRatioList
            ) {
              let businessDisList =
                this.projectDisRuleOptions.projectPerformanceDisDataResponse
                  .businessPerformanceRatioList
              this.businessPerformanceRatioList.forEach(v => {
                businessDisList.forEach(n => {
                  if (v.staffId == n.staffId) {
                    if (v.staffBusinessPerformance != n.staffBusinessPerformance) {
                      businessDisShow = true
                    }
                  }
                })
              })
            }
            if (this.projectDisRuleOptions.projectPerformanceDisDataResponse.deptPerformanceList) {
              let deptDisList =
                this.projectDisRuleOptions.projectPerformanceDisDataResponse.deptPerformanceList
              this.deptPerformanceList.forEach(v => {
                deptDisList.forEach(n => {
                  if (v.deptId == n.deptId) {
                    if (v.deptPerformance != n.deptPerformance) {
                      deptPerformanceDisShow = true
                    }
                  }
                })
              })
            }
          } catch (e) {}
          if (projectDisRuleOptionsShow || businessDisShow || deptPerformanceDisShow) {
            this.$confirm('项目绩效规则已修改末保存, 是否继续切换?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                //  确定
              })
              .catch(() => {
                // 取消
                this.activeName = '项目绩效'
                this.oldData = this.activeName
              })
          }
          this.oldData = this.activeName
          return
        }
      }
    },
    getStaffList() {
      this.$api.user.staffAllList({}).then(res => {
        this.user_Data = res.data
        this.$store.commit({
          type: 'business/SET_COMPANY_MEBERS',
          CompanyMembers: res.data,
        })
      })
    },
    getLinshi_initiator(v) {
      let i = ''
      this.user_Data.forEach(e => {
        if (e.id == v) {
          var arr = e.departmentName.split(',')
          i = arr[0] + '-' + e.userName
        }
      })
      return i
    },
    buttonProjectManagerFn(n) {
      let isShow = true
      if (this.projectInformation.projectDeptList.length <= 0 && isShow) {
        isShow = false
        this.$alert('请先选择部门后再进行选择', '', {
          confirmButtonText: '确定',
          callback: action => {},
        })
        this.$refs.buttonProjectManager.blur()
        this.$refs.deptCompetentManager.blur()
        this.$refs.buttonProjectLeaderManagerName.blur()
        return
      }
      if (n == 1) {
        this.$api.user
          .listStaff({
            pageSize: 1000000,
            deptId: this.projectInformation.projectDeptList[0].deptId,
          })
          .then(res => {
            if (res.data.records) {
              this.projectManagerFnList = []
              this.projectManagerFnList = res.data.records
              this.$forceUpdate()
            }
          })
        this.projectManager.id = null
        this.projectManager.userName = null
      } else if (n == 2) {
        this.$api.project
          .listDepartmentStaff({
            pageSize: 1000000,
            deptId: this.projectInformation.projectDeptList[0].deptId,
          })
          .then(res => {
            if (res.data.records) {
              this.deptCompetentManagerList = []
              this.deptCompetentManagerList = res.data.records
              this.$forceUpdate()
            }
          })
        this.deptCompetentManager.id = null
        this.deptCompetentManager.userName = null
      } else if (n === 3) {
        this.deputyGeneralId = null
        this.deputyGeneralName = null
      }
      this.projectManagerDlgVisible = true
    },
    projectManagerFn() {
      this.$api.user
        .listStaff({
          pageSize: 1000000,
          deptId: this.projectManager.id,
          userName: this.projectManager.userName,
        })
        .then(res => {
          if (res.data.records) {
            this.projectManagerFnList = []
            this.projectManagerFnList = res.data.records
            this.$forceUpdate()
          }
        })
    },
    deptCompetentManagerFn() {
      this.$api.project
        .listDepartmentStaff({
          pageSize: 1000000,
          deptId: this.deptCompetentManager.id,
          userName: this.deptCompetentManager.userName,
        })
        .then(res => {
          if (res.data.records) {
            this.deptCompetentManagerList = []
            this.deptCompetentManagerList = res.data.records
            this.$forceUpdate()
          }
        })
    },
    deputyGeneralManagerFn() {
      this.$api.project
        .listDepartmentHeadStaff({
          pageSize: 1000000,
          deptId: this.deputyGeneralId,
          userName: this.deputyGeneralName,
        })
        .then(res => {
          if (res.data.records) {
            this.deputyGeneralManagerList = []
            this.deputyGeneralManagerList = res.data.records
            this.$forceUpdate()
          }
        })
    },
    dataUpdate(row) {
      if (row) {
        this.projectInformation.vatData = null
        this.projectInformation.vatRate = null
      }
    },
    /** 值改变触发 "申请金额" 文本框 **/
    inputAmount(e) {
      let _money = 0
      this.projectInformation[e] = String(this.projectInformation[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      _money = this.projectInformation[e]
      this.AmountInWords[e] = changeMoneyToChinese(_money)
      this.$forceUpdate()
    },
    inputAmountprojectDeptList(id) {
      let projectDeptList = this.projectInformation.projectDeptList
      projectDeptList.forEach(v => {
        if (v.deptId == id) {
          v.predictAmount = String(v.predictAmount)
            .replace(/[^\d.]/g, '')
            .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        }
      })
      this.$forceUpdate()
    },
    inputAmountprojectOutsourceList(id) {
      let projectOutsourceList = this.projectInformation.projectOutsourceList
      projectOutsourceList.forEach(v => {
        if (v.currentTimeId == id) {
          if (
            Number.isNaN(v.outsourceCost) ||
            v.outsourceCost == undefined ||
            v.outsourceCost == null ||
            v.outsourceCost == ''
          ) {
            v.outsourceCost = 0
          } else {
            v.outsourceCost = String(v.outsourceCost)
              .replace(/[^\d.]/g, '')
              .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
          }
        }
      })

      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusAmount(e) {
      if (
        this.projectInformation[e] != null ||
        this.projectInformation[e] != '' ||
        this.projectInformation[e] != undefined
      ) {
        this.projectInformation[e] = String(this.projectInformation[e]).replace(/,/g, '')
      }
      this.$forceUpdate()
    },
    focusAmountprojectDeptList(id) {
      let projectDeptList = this.projectInformation.projectDeptList
      projectDeptList.forEach(v => {
        if (v.deptId == id) {
          if (v.predictAmount == null || v.predictAmount == '' || v.predictAmount == undefined) {
            v.predictAmount = 0
          } else {
            v.predictAmount = String(v.predictAmount).replace(/,/g, '')
          }
        }
      })
      this.$forceUpdate()
    },
    focusAmountprojectOutsourceList(id) {
      let projectOutsourceList = this.projectInformation.projectOutsourceList
      projectOutsourceList.forEach(v => {
        if (v.currentTimeId == id) {
          if (
            Number.isNaN(v.outsourceCost) ||
            v.outsourceCost == undefined ||
            v.outsourceCost == null ||
            v.outsourceCost == ''
          ) {
            v.outsourceCost = 0
          } else {
            v.outsourceCost = String(v.outsourceCost).replace(/,/g, '')
          }
        }
      })

      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount(e) {
      this.projectInformation[e] = Number(this.projectInformation[e]).toLocaleString()
    },
    blurAmountprojectDeptList(id) {
      let projectDeptList = this.projectInformation.projectDeptList
      projectDeptList.forEach(v => {
        if (v.deptId == id) {
          v.predictAmount = Number(v.predictAmount).toLocaleString()
        }
      })
      this.$forceUpdate()
    },
    blurAmountprojectOutsourceList(id) {
      let projectOutsourceList = this.projectInformation.projectOutsourceList
      projectOutsourceList.forEach(v => {
        if (v.currentTimeId == id) {
          v.outsourceCost = Number(Number(v.outsourceCost).toFixed(2))
          v.outsourceCost = Number(v.outsourceCost).toLocaleString()
        }
      })
      this.$forceUpdate()
    },

    returnFn() {
      this.resettingColor++
      this.BusinessPerformanceList = []
      this.DepartmentPerformanceList = []
      this.ExpenseRulesList = []
      this.FloatingWageList = []
      this.usedBusinessPerformanceList = []
      this.usedDepartmentPerformanceList = []
      this.usedExpenseRulesList = []
      this.usedFloatingWageList = []
      this.defaultChecked = []
      this.$emit('update:isShow', false)
      this.$nextTick(() => {
        if (this.$refs['form']) {
          this.$refs['form'].clearValidate('managerName')
        }
      })
      this.$store.commit({
        type: 'project/SET_PROJECTFILELIST',
        projectFileList: [],
      })
      this.$store.commit({
        type: 'project/ADD_PROJECTFILELIST',
        addAgreementFileList: [],
      })
      this.$store.commit({
        type: 'project/DEL_PROJECTFILELIST',
        delAgreementFileList: [],
      })
      this.$store.commit({
        type: 'project/SET_PROJECTDISRULEOPTIONS',
        ProjectDisRuleOptions: {},
      })
      this.$store.commit({
        type: 'project/SET_PROJECTINFORMATION',
        projectInformation: {},
      })
      this.indexKey++
    },

    handlePageNowChange(current) {
      this.selectPage.pageNow = current
      this.addProjectOutsourceList()
    },
    handlePagSizeChange(size) {
      this.selectPage.pageSize = size
      this.addProjectOutsourceList()
    },
    choiceRow(row) {
      this.isOutsource = false
      this.projectInformation.projectOutsourceList.push({
        outsourceName: row.name,
        outsourceCompanyId: row.id,
        currentTimeId: new Date().getTime(),
        content: null,
        memberQuantity: 0,
        outsourceCost: 0,
        phone: null,
        dockingPeople: null,
        projectOutsourceStartDate: null,
        projectOutsourceAccomplishDate: null,
        appraiseGrade: null,
        appraise: null,
      })
    },
    addProjectOutsourceList() {
      // 先从服务器中获取所有外协公司信息
      this.$api.outsource
        .getOutsourceData(this.selectPage)
        .then(res => {
          if (res.code !== 200) {
            this.$message.error('数据列表请求失败')
            return
          }
          this.selectPartnerCompanyList = res.data.records
          this.selectPage.total = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    getProjectOutsourceList() {
      this.selectPage.name = ''
      this.isOutsource = true
      this.addProjectOutsourceList()
    },

    delProjectOutsourceList(index) {
      this.projectInformation.projectOutsourceList.splice(index, 1)
    },

    // 项目经理-所属部门
    managerNameChange(res) {
      this.projectInformation.managerName = res.userName

      if (this.projectManager.id) {
        //如果选择部门后再选择人员，拿当前部门ID
        this.projectInformation.deptBelongManagerId = this.projectManager.id
      } else {
        // 没有选择部门直接选择人员
        const managerId = res.deptList.find(id => id == this.projectManagerDeptId)
        if (managerId) {
          this.projectInformation.deptBelongManagerId = managerId
        } else {
          this.projectInformation.deptBelongManagerId = res.deptList[0]
        }
      }
      this.projectInformation.deptClassifyManagerId = res.id

      this.$nextTick(() => {
        if (this.$refs['form']) {
          this.$refs['form'].clearValidate('managerName')
        }
      })
    },
    // 组长-所属部门
    leaderManagerNameChange(res) {
      this.projectInformation.leaderManagerName = res.userName
      this.projectInformation.deptLeaderManagerId = res.id
      if (this.projectManager.id) {
        //如果选择部门后再选择人员，拿当前部门ID
        this.projectInformation.deptLeaderDeptId = this.projectManager.id
      } else {
        // 没有选择部门直接选择人员
        const managerId = res.deptList.find(id => id == this.projectManagerDeptId)
        if (managerId) {
          this.projectInformation.deptLeaderDeptId = managerId
        } else {
          this.projectInformation.deptLeaderDeptId = res.deptList[0]
        }
      }
      this.$nextTick(() => {
        if (this.$refs['form']) {
          this.$refs['form'].clearValidate('leaderManagerName')
        }
      })
    },
    deptCompetentManagerChange(res) {
      this.projectInformation.competentManagerName = res.userName
      this.projectInformation.deptCompetentManagerId = res.id
      this.$forceUpdate()
    },
    deputyGeneralManagerChange(res) {
      this.projectInformation.fzManagerName = res.userName
      this.projectInformation.deptFzManagerId = res.id
      this.$forceUpdate()
    },

    getPost(deptId, postId) {
      var obj = {}
      this.projectInformation.projectDeptList.some(r => {
        if (r.deptId == deptId) {
          r.projectDeptBudgetList.some(n => {
            if (n.postId == postId) {
              obj = n
            }
          })
        }
      })
      return obj
    },
    getDept(deptId) {
      var obj = {}
      this.projectInformation.projectDeptList.some(r => {
        if (r.deptId == deptId) {
          obj = r
        }
      })
      return obj
    },
    choicePostDetermine(r) {
      if (r.length == 0) {
        this.$alert('请勾选部门', '', {
          confirmButtonText: '确定',
          callback: action => {},
        })
        return
      }
      this.projectManagerDeptId = r[0].id
      this.deptCompetentManagerDeptId = r[0].id
      var defaultChecked = []
      var projectDeptList = []
      var obj = {}
      r.some(r => {
        defaultChecked.push(r.id)
        var deptObj = this.getDept(r.id)
        obj[r.id] = {
          id: deptObj.id,
          deptId: r.id,
          deptName: r.deptName,
          projectId: this.options.id,
          projectDeptBudgetList: [],
          type: deptObj.type,
          managerId: deptObj.managerId,
          managerName: deptObj.managerName,
          predictAmount: deptObj.predictAmount ? deptObj.predictAmount : 0,
          taskRatio: deptObj.taskRatio ? deptObj.taskRatio : 0,
        }
      })
      for (let k in obj) {
        projectDeptList.push(obj[k])
      }
      this.projectInformation.projectDeptList = projectDeptList
      this.$store.commit({
        type: 'project/SET_PROJECTINFORMATION',
        projectInformation: this.projectInformation,
      })

      this.defaultChecked = defaultChecked
      this.$nextTick(() => {
        this.$refs?.form.clearValidate('managerName', valid => {
          //valid返回的是规则集中写的错误提示信息，如果满足条件，返回的就是空
          if (!valid) {
            return
          }
        })
      })

      this.showChoicePost = false
    },
    init() {
      if (this.options.id) {
        this.loading = true
        this.$api.project
          .selectDetailById(this.options.id)
          .then(res => {
            this.$store.commit({
              type: 'project/SET_ORIGINALFORM',
              originalForm: res.data.deepClone(),
            })
            this.$store.commit({
              type: 'project/SET_PROJECTINFORMATION',
              projectInformation: res.data.projectInformation,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTCOSTPLANLIST',
              projectCostPlanList: res.data.projectCostPlanList,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTDISPOSABLEEXPENSES',
              projectDisposableExpenses: res.data.projectDisposableExpenses,
            })
            this.selectProjectName = res.data.projectInformation?.projectName
            if (res.data.projectInformation != null) {
              // 保存旧数据
              this.oldProjectInformation = res.data.projectInformation.deepClone()
              this.oldProjectInformation.projectOutsourceList.forEach(v => {
                if (v.outsourceCost) {
                  v.outsourceCost = Number(delcommafy(v.outsourceCost))
                }
                if (v.memberQuantity) {
                  v.memberQuantity = Number(delcommafy(v.memberQuantity))
                }
              })
              this.oldProjectCostPlanFileList =
                res.data.projectInformation.projectCostPlanFileList.deepClone()
              this.temporaryVatRate = res.data.projectInformation.vatRate
                ? res.data.projectInformation.vatRate
                : ''
              this.oldvatRate = res.data.projectInformation.deepClone()
              var Obj = res.data.projectInformation.deepClone()
              if (this.projectInformation.projectDeptList.length > 0) {
                this.projectInformation.projectDeptList.forEach(v => {
                  if (v.predictAmount) {
                    v.predictAmount = Number(v.predictAmount).toLocaleString()
                  }
                })
                this.projectManagerDeptId = res.data.projectInformation.projectDeptList[0].deptId
              }
              if (this.projectInformation.projectOutsourceList.length > 0) {
                this.projectInformation.projectOutsourceList.forEach(v => {
                  if (v.outsourceCost) {
                    v.outsourceCost = Number(v.outsourceCost).toLocaleString()
                  }
                })
              }

              for (const key in this.AmountInWords) {
                for (const key1 in Obj) {
                  if (!Array.isArray(this.AmountInWords[key]) && !Array.isArray(Obj[key1])) {
                    if (key == key1) {
                      this.AmountInWords[key] = changeMoneyToChinese(Obj[key1])
                      this.projectInformation[key] = Number(Obj[key1]).toLocaleString()
                    }
                  }
                }
              }
            }

            // 项目可支配费用
            if (res.data.projectDisposableExpenses != null) {
              let ObjProjectDisposableExpenses = res.data.projectDisposableExpenses.deepClone()

              for (const key in this.projectDisposableExpenses) {
                for (const key1 in ObjProjectDisposableExpenses) {
                  if (
                    !Array.isArray(this.projectDisposableExpenses[key]) &&
                    !Array.isArray(ObjProjectDisposableExpenses[key1])
                  ) {
                    if (
                      key == key1 &&
                      key != 'id' &&
                      key != 'projectId' &&
                      key != 'teamAmountRemark' &&
                      key != 'staffAmountRemark'
                    ) {
                      this.projectDisposableExpenses[key] = Number(
                        ObjProjectDisposableExpenses[key1]
                      ).toLocaleString()
                    }
                  }
                }
              }
            }

            this.$store.commit({
              type: 'project/SET_PROJECTFILELIST',
              projectFileList: res.data.projectFileList,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTDEPTMEMBERLIST',
              projectDeptMemberList: res.data.projectDeptMemberList,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTDEPTBELONGLIST',
              projectDeptBelongList: res.data.projectDeptBelongList,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTSTAGEPLANCOLLECT',
              projectStagePlanCollect: res.data.projectStagePlanCollect,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTSTAGEBELONGLIST',
              projectStageBelongList: res.data.projectStageBelongList,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTSTAGEPLANCOLLECT',
              projectStagePlanCollect: res.data.projectStagePlanCollect,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTSTAGETASKLIST',
              projectStageTaskList: res.data.projectStageTaskList,
            })
            this.$store.commit({
              type: 'project/SET_AGREEMENTSTAGERESPONSELIST',
              agreementStageResponseList: res.data.agreementStageResponseList,
            })
            this.$store.commit({
              type: 'project/SET_PROJECTCOSTPLANFILELIST',
              projectCostPlanFileList: res.data?.projectInformation?.projectCostPlanFileList,
            })

            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        this.loading = true
        if (this.achievementsOptions.id) {
          if (this.achievementsOptions.achievements == 1) {
            this.$api.dict
              .listSysDictData('GENERAL_RULE_MODULE', true)
              .then(res => {
                this.rulesType = res.data
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
            this.$api.project
              .selectRuleDetailById(this.achievementsOptions.id)
              .then(res => {
                res.data.ruleList.forEach(v => {
                  if (v.module == 'IMPLEMENTATIONMANAGEMENT_PERFORMANCE_RULE') {
                    //部门
                    this.DepartmentPerformance.module = v.module
                    this.DepartmentPerformance.projectId = v.projectId

                    this.DepartmentPerformanceList.push(v)
                  } else if (v.module == 'BUSINESS_PERFORMANCE_RULE') {
                    //商务
                    this.BusinessPerformance.module = v.module
                    this.BusinessPerformance.projectId = v.projectId

                    this.BusinessPerformanceList.push(v)
                  } else if (v.module == 'DISPOSABLE_EXPENSES_RULE') {
                    //可支配
                    this.ExpenseRules.module = v.module
                    this.ExpenseRules.projectId = v.projectId
                    this.ExpenseRulesList.push(v)
                  } else if (v.module == 'FLOATING_WAGE_RULE') {
                    //工资
                    this.FloatingWage.module = v.module
                    this.FloatingWage.projectId = v.projectId
                    this.FloatingWageList.push(v)
                  }
                })

                this.usedFloatingWageList = this.FloatingWageList.deepClone()
                this.usedBusinessPerformanceList = this.BusinessPerformanceList.deepClone()
                this.usedDepartmentPerformanceList = this.DepartmentPerformanceList.deepClone()
                this.usedExpenseRulesList = this.ExpenseRulesList.deepClone()

                this.$store.commit({
                  type: 'project/SET_PROJECTINFORMATION',
                  projectInformation: res.data.projectInformation,
                })
                if (res.data.projectInformation != null) {
                  var Obj = res.data.projectInformation.deepClone()
                  if (this.projectInformation.projectDeptList.length > 0) {
                    this.projectInformation.projectDeptList.forEach(v => {
                      if (v.predictAmount) {
                        v.predictAmount = Number(v.predictAmount).toLocaleString()
                      }
                    })
                  }
                  if (this.projectInformation.projectOutsourceList.length > 0) {
                    this.projectInformation.projectOutsourceList.forEach(v => {
                      if (v.outsourceCost) {
                        v.outsourceCost = Number(v.outsourceCost).toLocaleString()
                      }
                    })
                  }

                  for (const key in this.AmountInWords) {
                    for (const key1 in Obj) {
                      if (!Array.isArray(this.AmountInWords[key]) && !Array.isArray(Obj[key1])) {
                        if (key == key1) {
                          this.AmountInWords[key] = changeMoneyToChinese(Obj[key1])
                          this.projectInformation[key] = Number(Obj[key1]).toLocaleString()
                        }
                      }
                    }
                  }
                }

                this.loading = false
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          } else if (this.achievementsOptions.achievements == 2) {
            this.$api.project
              .projectRuleSelectRuleDisDataDetailById(this.achievementsOptions.id)
              .then(res => {
                this.$store.commit({
                  type: 'project/SET_PROJECTINFORMATION',
                  projectInformation: res.data.projectInformation,
                })
                this.projectDisRuleOptions = res.data
                if (res.data.projectPerformanceDisDataResponse) {
                  this.usedProjectDisRuleDisDataResponse =
                    res.data.projectPerformanceDisDataResponse.deepClone()
                }
                if (res.data.projectPerformanceDisDataResponse) {
                  this.businessPerformanceRatioList =
                    res.data.projectPerformanceDisDataResponse.businessPerformanceRatioList.deepClone()
                }
                if (res.data.projectPerformanceDisDataResponse) {
                  this.deptPerformanceList =
                    res.data.projectPerformanceDisDataResponse.deptPerformanceList.deepClone()
                }
                if (res.data.projectInformation != null) {
                  var Obj = res.data.projectInformation.deepClone()
                  if (this.projectInformation.projectDeptList.length > 0) {
                    this.projectInformation.projectDeptList.forEach(v => {
                      if (v.predictAmount) {
                        v.predictAmount = Number(v.predictAmount).toLocaleString()
                      }
                    })
                  }
                  if (this.projectInformation.projectOutsourceList.length > 0) {
                    this.projectInformation.projectOutsourceList.forEach(v => {
                      if (v.outsourceCost) {
                        v.outsourceCost = Number(v.outsourceCost).toLocaleString()
                      }
                    })
                  }

                  for (const key in this.AmountInWords) {
                    for (const key1 in Obj) {
                      if (!Array.isArray(this.AmountInWords[key]) && !Array.isArray(Obj[key1])) {
                        if (key == key1) {
                          this.AmountInWords[key] = changeMoneyToChinese(Obj[key1])
                          this.projectInformation[key] = Number(Obj[key1]).toLocaleString()
                        }
                      }
                    }
                  }
                }
                this.loading = false
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          }
        } else if (this.business.id) {
          this.loading = false
          this.$store.commit({
            type: 'project/SET_PROJECTINFORMATION',
            projectInformation: {
              businessManageId: this.business.id,
              businessType: this.business.businessType,
              projectName: this.business.businessName ? this.business.businessName : null,
              projectSource: this.business.projectSource ? this.business.projectSource : null,
              projectSourceDetailed: this.business.projectSourceDetailed
                ? this.business.projectSourceDetailed
                : null,
              projectStartBy: this.business.provideId ? this.business.provideId : null,
              customersName: this.business.contacts ? this.business.contacts : null,
              customersUnit: this.business.ownerUnit ? this.business.ownerUnit : null,
              predictContractAmount: this.business.predictMoney ? this.business.predictMoney : 0, //预计合同金额
              actualAmount: 0, //2024-03-14-项目创立时，实际金额移除商务合同金额赋值
              businessLines: this.business.businessLines ? this.business.businessLines : null,
              projectLines: this.business.projectLines ? this.business.projectLines : null,
              vatType: this.business.vatType ? this.business.vatType : null, //剩余工作量比例
              vatData: this.business.vatData ? this.business.vatData : null, //剩余工作量比例
              vatRate: this.business.vatRate ? this.business.vatRate : 0, //税率
              actualVat: this.business.actualVat ? this.business.actualVat : 0, //增值税
              actualAdditionalTax: this.business.actualAdditionalTax
                ? this.business.actualAdditionalTax
                : 0, //附加税
              actualStampTax: this.business.actualStampTax ? this.business.actualStampTax : 0, //印花税
              tenderFee: this.business.tenderFee ? this.business.tenderFee : 0, //招标费
              collaborationCostsRatio: this.business.collaborationCostsRatio
                ? this.business.collaborationCostsRatio
                : 0, //合作费比例
              projectDeptList: [],
              projectOutsourceList: [],
              projectBudget: 0, //项目预计成本
              managerName: null,
              leaderManagerName: null,
              exteriorBusinessCosts: 0,
              disposableProjectLaborhour: 0,
              actualContractAmount: 0, //实际合同金额
              residualWorkRatio: null, //剩余工作量比例
              depreciationEquipmentFee: 0, //设备折旧费
              additionalTaxRate: 0, //附加税税率
              stampTaxRate: 0, //印花税税率
              remark: null,
            },
          })

          this.temporaryVatRate = this.business.vatRate ? this.business.vatRate : null

          for (const key in this.AmountInWords) {
            for (const key1 in this.projectInformation) {
              if (
                !Array.isArray(this.AmountInWords[key]) &&
                !Array.isArray(this.projectInformation[key1])
              ) {
                if (key == key1) {
                  this.AmountInWords[key] = changeMoneyToChinese(this.projectInformation[key1])
                  this.projectInformation[key1] = Number(
                    this.projectInformation[key1]
                  ).toLocaleString()
                }
              }
            }
          }
        } else {
          this.loading = false
          this.$store.commit({
            type: 'project/SET_PROJECTINFORMATION',
            projectInformation: {
              projectDeptList: [],
              projectOutsourceList: [],
              predictContractAmount: 0,
              projectBudget: 0,
              actualContractAmount: 0,
              disposableProjectLaborhour: 0,
              tenderFee: 0,
              actualAmount: 0,
              vatRate: 0,
            },
          })
        }
        this.$store.commit({
          type: 'project/SET_PROJECTFILELIST',
          projectFileList: [],
        })
        this.$store.commit({
          type: 'project/SET_PROJECTDEPTMEMBERLIST',
          projectDeptMemberList: [],
        })
        this.$store.commit({
          type: 'project/SET_PROJECTDEPTBELONGLIST',
          projectDeptBelongList: [],
        })
        this.$store.commit({
          type: 'project/SET_PROJECTSTAGEPLANCOLLECT',
          projectStagePlanCollect: {},
        })
        this.$store.commit({
          type: 'project/SET_PROJECTDISPOSABLEEXPENSES',
          projectDisposableExpenses: {},
        })
        this.$store.commit({
          type: 'project/SET_PROJECTSTAGEBELONGLIST',
          projectStageBelongList: [],
        })
        this.$store.commit({
          type: 'project/SET_PROJECTSTAGEPLANCOLLECT',
          projectStagePlanCollect: [],
        })
        this.$store.commit({
          type: 'project/SET_PROJECTSTAGETASKLIST',
          projectStageTaskList: [],
        })
        this.$store.commit({
          type: 'project/SET_AGREEMENTSTAGERESPONSELIST',
          agreementStageResponseList: [],
        })
        this.$store.commit({
          type: 'project/SET_PROJECTCOSTPLANLIST',
          projectCostPlanList: [],
        })

        this.$store.commit({
          type: 'project/SET_PROJECTCOSTPLANFILELIST',
          projectCostPlanFileList: [],
        })

        // this.loading = false;
      }
    },
    submit(key, isRecord = false) {
      this.projectInformation.taxesFee = this.SJ_taxes //实际总税费
      this.projectInformation.stampTaxRate = 0.03 //印花税率
      this.projectInformation.additionalTaxRate = 12 //附加税率
      this.projectInformation.vatRate = Number(this.projectInformation.vatRate) //增值税率
      this.projectInformation.actualVat = this.Sj_configcomp //实际增值税
      this.projectInformation.actualAdditionalTax = this.Sj_predictAdditionalTax //实际附加税
      this.projectInformation.actualStampTax = this.Sj_predictStampTax //实际印花税

      for (const key in this.projectInformation) {
        for (const key1 in this.AmountInWords) {
          if (
            !Array.isArray(this.projectInformation[key]) &&
            !Array.isArray(this.AmountInWords[key1])
          ) {
            if (key == key1) {
              this.projectInformation[key] = Number(delcommafy(this.projectInformation[key]))
            }
          }
        }
      }

      this.projectInformation.projectDeptList.forEach(v => {
        if (v.predictAmount) {
          v.predictAmount = Number(delcommafy(v.predictAmount))
        }
      })
      this.projectInformation.projectOutsourceList.forEach(v => {
        if (v.outsourceCost) {
          v.outsourceCost = Number(delcommafy(v.outsourceCost))
        }
        if (v.memberQuantity) {
          v.memberQuantity = Number(delcommafy(v.memberQuantity))
        }
      })

      this.projectInformation.actualStampTax = Number(
        delcommafy(this.projectInformation.actualStampTax)
      )
      this.projectInformation.actualAdditionalTax = Number(
        delcommafy(this.projectInformation.actualAdditionalTax)
      )
      this.projectInformation.actualVat = Number(delcommafy(this.projectInformation.actualVat))
      this.projectInformation.depreciationEquipmentFee = Number(
        delcommafy(this.projectInformation.depreciationEquipmentFee)
      )
      this.projectInformation.tenderFee = Number(delcommafy(this.projectInformation.tenderFee))
      this.projectInformation.actualAmount = Number(
        delcommafy(this.projectInformation.actualAmount)
      )
      this.projectInformation.actualContractAmount = Number(
        delcommafy(this.projectInformation.actualContractAmount)
      )
      this.projectInformation.projectBudget = Number(
        delcommafy(this.projectInformation.projectBudget)
      )
      this.projectInformation.predictContractAmount = Number(
        delcommafy(this.projectInformation.predictContractAmount)
      )
      this.projectInformation.collaborationCostsRatio = Number(
        delcommafy(this.projectInformation.collaborationCostsRatio)
      )
      // 剩余工作量比例(%)移除类型转换
      if (this.projectInformation.residualWorkRatio !== null) {
        this.projectInformation.residualWorkRatio = Number(
          delcommafy(this.projectInformation.residualWorkRatio)
        )
      }
      // 暂存、暂存提交、保存、立项
      if (isRecord && this.activeName == '0') {
        this.oldProjectInformation.actualContractAmount = Number(
          delcommafy(this.oldProjectInformation.actualContractAmount)
        )
        // 筛选出修改过的字段
        let editArr = []
        if (this.oldProjectInformation && this.projectInformation) {
          Object.keys(this.oldProjectInformation).forEach(oldKey => {
            let old = this.oldProjectInformation[oldKey]
            if (typeof this.oldProjectInformation[oldKey] === 'string') {
              old = this.oldProjectInformation[oldKey].trim()
            }
            Object.keys(this.projectInformation).forEach(key => {
              let e = this.projectInformation[key]
              if (typeof this.projectInformation[key] === 'string') {
                e = this.projectInformation[key].trim()
              }
              if (
                oldKey === key &&
                !['projectDeptList', 'projectOutsourceList', 'projectCostPlanFileList'].includes(
                  oldKey
                ) &&
                !['projectDeptList', 'projectOutsourceList', 'projectCostPlanFileList'].includes(
                  key
                ) &&
                old !== e
              ) {
                editArr.push(key)
              }
            })
          })

          this.oldProjectInformation.projectDeptList.forEach((oldObj, oldIndex) => {
            const newIndex = this.projectInformation.projectDeptList.findIndex(
              (newObj, index) => oldIndex === index
            )
            if (newIndex !== -1) {
              const oldKeys = Object.keys(oldObj)
              const newKeys = Object.keys(this.projectInformation.projectDeptList[newIndex])

              oldKeys.forEach(oldKey => {
                let old = oldObj[oldKey]
                if (typeof oldObj[oldKey] === 'string') {
                  old = oldObj[oldKey].trim()
                }

                if (newKeys.includes(oldKey)) {
                  let e = this.projectInformation.projectDeptList[newIndex][oldKey]
                  if (typeof e === 'string') {
                    e = e.trim()
                  }

                  if (old !== e) {
                    editArr.push(oldKey)
                  }
                }
              })
            }
          })
          const newDeptLength = this.projectInformation.projectDeptList.length
          const oldDeptLength = this.oldProjectInformation.projectDeptList.length
          const practicalDeptLength = this.oldProjectInformation.projectDeptList.length - 1
          // 新增部门信息
          if (newDeptLength > oldDeptLength) {
            this.projectInformation.projectDeptList.forEach((v, i) => {
              if (practicalDeptLength < i) {
                Object.keys(v).forEach(Vkey => {
                  editArr.push(Vkey)
                })
              }
            })
          } else if (newDeptLength < oldDeptLength) {
            this.projectInformation.projectDeptList.forEach((v, i) => {
              Object.keys(v).forEach(Vkey => {
                editArr.push(Vkey)
              })
            })
          }

          this.oldProjectInformation.projectOutsourceList.forEach((oldObj, oldIndex) => {
            const newIndex = this.projectInformation.projectOutsourceList.findIndex(
              (newObj, index) => oldIndex === index
            )
            if (newIndex !== -1) {
              const oldKeys = Object.keys(oldObj)
              const newKeys = Object.keys(this.projectInformation.projectOutsourceList[newIndex])

              oldKeys.forEach(oldKey => {
                let old = oldObj[oldKey]
                if (typeof oldObj[oldKey] === 'string') {
                  old = oldObj[oldKey].trim()
                }

                if (newKeys.includes(oldKey)) {
                  let e = this.projectInformation.projectOutsourceList[newIndex][oldKey]
                  if (typeof e === 'string') {
                    e = e.trim()
                  }

                  if (old !== e) {
                    editArr.push(oldKey)
                  }
                }
              })
            }
          })
          const newLength = this.projectInformation.projectOutsourceList.length
          const oldLength = this.oldProjectInformation.projectOutsourceList.length
          const practicalLength = this.oldProjectInformation.projectOutsourceList.length - 1
          // 新增外协信息
          if (newLength > oldLength) {
            this.projectInformation.projectOutsourceList.forEach((v, i) => {
              if (practicalLength < i) {
                Object.keys(v).forEach(Vkey => {
                  if (!['outsourceCompanyId', 'currentTimeId'].includes(Vkey)) {
                    editArr.push(Vkey)
                  }
                })
              }
            })
          } else if (newLength < oldLength) {
            this.projectInformation.projectOutsourceList.forEach((v, i) => {
              Object.keys(v).forEach(Vkey => {
                editArr.push(Vkey)
              })
            })
          }

          if (this.oldProjectCostPlanFileList && this.projectCostPlanFileList) {
            this.oldProjectCostPlanFileList.forEach((oldObj, oldIndex) => {
              const newIndex = this.projectCostPlanFileList.findIndex(
                (newObj, index) => oldIndex === index
              )
              if (newIndex !== -1) {
                const oldKeys = Object.keys(oldObj)
                const newKeys = Object.keys(this.projectCostPlanFileList[newIndex])

                oldKeys.forEach(oldKey => {
                  let old = oldObj[oldKey]
                  if (typeof oldObj[oldKey] === 'string') {
                    old = oldObj[oldKey].trim()
                  }

                  if (newKeys.includes(oldKey)) {
                    let e = this.projectCostPlanFileList[newIndex][oldKey]
                    if (typeof e === 'string') {
                      e = e.trim()
                    }

                    if (old !== e) {
                      editArr.push(oldKey)
                    }
                  }
                })
              }
            })
          }
          const newCostPlanLLength = this.projectCostPlanFileList.length
          const oldCostPlanLLength = this.oldProjectCostPlanFileList.length
          const practicalCostPlanLLength = this.oldProjectCostPlanFileList.length - 1
          // 新增预估成本方案信息
          if (newCostPlanLLength > oldCostPlanLLength) {
            this.projectCostPlanFileList.forEach((v, i) => {
              if (practicalCostPlanLLength < i) {
                Object.keys(v).forEach(Vkey => {
                  if (['fileContent', 'fileName'].includes(Vkey)) {
                    editArr.push(Vkey)
                  }
                })
              }
            })
          } else if (newCostPlanLLength < oldCostPlanLLength) {
            this.projectCostPlanFileList.forEach((v, i) => {
              Object.keys(v).forEach(Vkey => {
                editArr.push(Vkey)
              })
            })
          }
          // 去重
          const newEditArr = [...new Set(editArr)]
          // 传递记录修改字段给予后端
          this.projectInformation.changeFieldList = newEditArr
        }
      }

      for (const key in this.projectDisposableExpenses) {
        if (!Array.isArray(this.projectDisposableExpenses[key])) {
          if (
            key != 'id' &&
            key != 'projectId' &&
            key != 'teamAmountRemark' &&
            key != 'staffAmountRemark'
          ) {
            this.projectDisposableExpenses[key] = Number(
              delcommafy(this.projectDisposableExpenses[key])
            )
          }
        }
      }
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          this.$api.project[key](this.options)
            .then(res => {
              this.submitLoading = false
              this.refresh(1)
              this.indexKey++
              this.$message.success('操作成功！')
              this.$store.commit({
                type: 'project/ADD_PROJECTFILELIST',
                addAgreementFileList: [],
              })
              this.$store.commit({
                type: 'project/DEL_PROJECTFILELIST',
                delAgreementFileList: [],
              })
              this.$store.commit({
                type: 'project/SET_PROJECTFILELIST',
                projectFileList: [],
              })
            })
            .catch(err => {
              this.submitLoading = false
              console.log(err, 'err')
            })
        } else {
          this.activeName = '0'
          this.$nextTick(() => {
            this.scrollView(obj)
          })
        }
      })
    },
    achievements(key) {
      let text = ''
      if (key == 'editOrSubmit') {
        text = '是否提交绩效方案'
      } else {
        text = `是否要修改${this.activeName}页面`
      }
      this.$confirm(`${text}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (key == 'selectRuleEdit') {
            this.submitLoading = true
            if (this.oldData == '可支配费用规则') {
              this.$api.project[key](this.$refs.expenserules.ExpenseList)
                .then(res => {
                  this.submitLoading = false
                  this.ExpenseRulesList = []
                  this.usedExpenseRulesList = []
                  this.init()
                  this.$forceUpdate()
                  this.activeName = this.oldData
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err, 'err')
                })
            } else if (this.oldData == '商务绩效规则') {
              this.$api.project[key](this.$refs.businessperformance.BusinessList)
                .then(res => {
                  this.submitLoading = false
                  this.BusinessPerformanceList = []
                  this.usedBusinessPerformanceList = []
                  this.init()
                  this.$forceUpdate()
                  this.activeName = this.oldData
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err, 'err')
                })
            } else if (this.oldData == '部门绩效规则') {
              this.$api.project[key](this.$refs.departmentperformance.DepartmentList)
                .then(res => {
                  this.submitLoading = false
                  this.DepartmentPerformanceList = []
                  this.usedDepartmentPerformanceList = []
                  this.init()
                  this.$forceUpdate()
                  this.activeName = this.oldData
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err, 'err')
                })
            } else if (this.oldData == '浮动工资') {
              this.$api.project[key](this.$refs.floatingwage.FloatingList)
                .then(res => {
                  this.submitLoading = false
                  this.FloatingWageList = []
                  this.usedFloatingWageList = []
                  this.init()
                  this.$forceUpdate()
                  this.activeName = this.oldData
                  this.$message.success('操作成功！')
                })
                .catch(err => {
                  this.submitLoading = false
                  console.log(err, 'err')
                })
            }
            return
          }

          if (key == 'projectPerformanceEdit') {
            let projectPerformanceDisDataRequest =
              this.$refs.projectperformance.projectPerformanceDisDataResponse
            for (const key in projectPerformanceDisDataRequest) {
              for (const key1 in this.projectPerformanceIsAlreadyChangeRequest) {
                if (
                  !Array.isArray(projectPerformanceDisDataRequest[key]) &&
                  !Array.isArray(
                    this.projectPerformanceIsAlreadyChangeRequest[key1] &&
                      key != 'projectId' &&
                      key1 != 'projectId'
                  )
                ) {
                  if (key == key1) {
                    projectPerformanceDisDataRequest[key1] = Number.parseFloat(
                      delcommafy(projectPerformanceDisDataRequest[key1])
                    )
                  }
                }
              }
            }
            this.submitLoading = true
            let obj = {
              projectId: this.projectDisRuleOptions.id,
              projectPerformanceDisDataRequest: projectPerformanceDisDataRequest,
              projectPerformanceIsAlreadyChange:
                this.$refs.projectperformance.projectPerformanceIsAlreadyChangeRequest,
            }
            this.$api.project[key](obj)
              .then(res => {
                this.$message.success('保存成功！')
                this.init()
                this.resettingColor++
                this.submitLoading = false
              })
              .catch(err => {
                console.log(err)
                this.submitLoading = false
              })
            return
          }
          if (key == 'editOrSubmit') {
            let projectPerformanceDisDataRequest =
              this.$refs.projectperformance.projectPerformanceDisDataResponse
            for (const key in projectPerformanceDisDataRequest) {
              for (const key1 in this.projectPerformanceIsAlreadyChangeRequest) {
                if (
                  !Array.isArray(projectPerformanceDisDataRequest[key]) &&
                  !Array.isArray(
                    this.projectPerformanceIsAlreadyChangeRequest[key1] &&
                      key != 'projectId' &&
                      key1 != 'projectId'
                  )
                ) {
                  if (key == key1) {
                    projectPerformanceDisDataRequest[key1] = Number.parseFloat(
                      delcommafy(projectPerformanceDisDataRequest[key1])
                    )
                  }
                }
              }
            }
            this.submitLoading = true
            let obj = {
              projectId: this.projectDisRuleOptions.id,
              projectPerformanceDisDataRequest: projectPerformanceDisDataRequest,
              projectPerformanceIsAlreadyChange:
                this.$refs.projectperformance.projectPerformanceIsAlreadyChangeRequest,
            }
            this.submitLoading = true
            this.$api.project[key](obj)
              .then(res => {
                this.$message.success('提交成功！')
                this.refresh(1)
                this.submitLoading = false
              })
              .catch(err => {
                console.log(err)
                this.submitLoading = false
              })
            return
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消修改',
          })
          this.loading = false
        })
    },
    approvePass(obj) {
      this.submitLoading = true
      this.$api.project
        .approvePass(obj)
        .then(res => {
          this.submitLoading = false
          this.refresh(1)
          this.$message.success('操作成功！')
          this.handleClose()
        })
        .catch(err => {
          this.submitLoading = false
          console.log(err)
        })
    },
    approve(a) {
      this.approveTxet = a
      this.dialogVisible = true
    },
    handleClose() {
      this.$refs.rulesApproveTxet.resetFields()
      this.form.checkOpinion = ''
      this.dialogVisible = false
      this.approveTxet = ''
    },
    addApprove() {
      this.$refs.rulesApproveTxet.validate(valid => {
        if (valid) {
          this.form.id = this.options.id
          if (this.approveTxet == 1) {
            this.approveReject(this.form)
          } else if (this.approveTxet == 2) {
            if (this.form.checkOpinion == '') {
              this.form.checkOpinion = '通过'
            }
            this.approvePass(this.form)
          }
        }
      })
    },
    approveReject(obj) {
      this.submitLoading = true
      this.$api.project
        .approveReject(obj)
        .then(res => {
          this.submitLoading = false
          this.refresh(1)
          this.$message.success('操作成功！')
          this.handleClose()
        })
        .catch(err => {
          this.submitLoading = false
          console.log(err)
        })
    },
    toDecimal(x) {
      var f = parseFloat(x)
      if (isNaN(f)) {
        return
      }
      f = Math.round(x * 100) / 100
      return f
    },
    partyPhone(rule, value, callback) {
      if (value == '' || value == null || value == undefined) {
        return callback()
      }
      const reg =
        /^(((\d{3,4}-)?[0-9]{5,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/ //手机号正则表达式或座机号

      if (reg.test(value)) {
        callback()
      } else {
        return callback(new Error('请输入正确的手机号或座机号'))
      }
    },

    projectQuerySearch(queryString, cb) {
      let projectNameList = this.projectNameList
      let results = queryString ? this.projectNameCreateFilter(queryString) : projectNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    projectNameCreateFilter(queryString) {
      let results = this.projectNameList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onProjectNameSelect(e) {
      if (e) {
        this.projectInformation.projectName = e.value
        this.$refs?.form.validateField('projectName')
      }
      this.$forceUpdate()
    },

    clearColumnPadding({ columnIndex }) {
      if (columnIndex == 1) {
        return 'cellName clearBorder'
      }
    },

    // 显示业务类型弹框
    showBusinessLinesIsShow() {
      this.$api.dict
        .getTreeDataList({ dictType: 'BUSINESS_LINES' })
        .then(res => {
          this.businessLinesTableData = res.data
          this.businessLinesIsShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    vatTypeTableCellStyle({ row, columnIndex }) {
      let styleArr = ['cellName']

      if (row.dictDataThreeList) {
        styleArr.push('cellContent')
        if (row.hasChildren && columnIndex == 1) {
          styleArr.push('clearBorder')
        }
        return styleArr.join(' ')
      } else {
        styleArr.push('cellTitle')
        if (columnIndex == 0) {
          styleArr.push('cellTitleFont')
        } else if (columnIndex == 2) {
          styleArr.push('cellTitleAlign')
        }
        return styleArr.join(' ')
      }
    },

    // 显示增值税弹框
    showVatTypesShow() {
      this.$api.vat
        .getTreeDataList()
        .then(res => {
          this.VatTypesTableData = res.data
          this.VatTypesTableData.forEach((item, index) => {
            item.id = index + ''
            if (item.dictDataTwoList) {
              item.dictDataTwoList.forEach((item2, index2) => {
                if (item2.dictDataThreeList && item2.dictDataThreeList.length !== 0) {
                  item2.hasChildren = true
                }
                item2.id = String(index) + '&' + String(index2)
              })
            }
          })
          this.vatTypesShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    arraySpanMethod({ row, columnIndex }) {
      if (row.hasChildren) {
        if (columnIndex == 1) {
          return [1, 2]
        }
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.fullScreen {
  .cost {
    padding: 20px 0;
    /deep/.el-timeline-item__timestamp {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
  .danwei {
    position: absolute;
    right: 2%;
  }
  /deep/#checkOpinion {
    .el-form-item__label {
      font-size: 16px !important;
      color: red !important;
    }
  }
  .Taxes {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding-left: 12px;
  }
}
.father {
  position: relative;
}
.son {
  position: absolute;
  left: 0px;
  bottom: -38px;
  white-space: nowrap;
}
#projectOutsourceList {
  /deep/.el-table .el-table__cell .cell {
    padding: 16px 10px;
  }
}
/deep/.vatLabelClassName {
  width: 12.5%;
}
/deep/.taxLabelClassName {
  width: 16.6%;
}
/deep/.cellName {
  .cell {
    padding: 0;
  }
  padding: 0 !important;
}

.remark-box {
  padding-left: 10px;
}
/deep/.height-light {
  color: red;
  font-size: 20px;
}
.vatDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 25px;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 0.1px solid #303133 !important;
  }
  /deep/.cellContent {
    background-color: rgb(222, 235, 247) !important;
    text-align: center;
  }
  /deep/.cellTitle {
    background-color: rgb(189, 215, 238) !important;
  }
  /deep/.el-table tbody tr:hover > td {
    background-color: transparent;
  }
  /deep/.cellTitleFont {
    font-weight: 700;
  }
  /deep/.cellTitleAlign {
    text-align: center;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
}

.businessLinesDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 25px;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 1px solid #303133 !important;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
}
.margin-top {
  position: relative;
}
.projectEdit {
  position: absolute;
  top: 12px;
  right: 5px;
  width: 105px;
  .el-button {
    width: 100%;
  }
}
/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 8px 10px;
  .el-form-item {
    margin-bottom: 6px;
  }
}
/deep/.fullScreenMain .fullScreenMainCon .el-tab-pane > .el-form {
  padding-top: 0px;
}

/deep/.el-card .el-card__body {
  display: block;
}
</style>
